import React from "react";
import Wrapper from "../components/wrapper/wrapper";

function OfferPage() {
  return (
    <>
       <Wrapper />
    </>
  );
}

export default OfferPage;
