import React, { useRef } from 'react';
import { BoundingClient, CampaignType, Offer, PopupSettings, TemplateOptionSettings, } from '../../types/global.types';
import EmailPopup from '../item/emailPopup';


interface Props {
    settings: PopupSettings,
    templateSettings?: TemplateOptionSettings,
    isEmbedded?: boolean,
    setIsEmail?: React.Dispatch<React.SetStateAction<boolean>>,
    setSingle?: React.Dispatch<React.SetStateAction<Offer | undefined>>,
    prePopulateEmail?: string | undefined,
    emailPopupOffer: Offer,
    emailPopupPosition?: BoundingClient,
    campaignObj: CampaignType,
    campaignId: string,
    placementId: string,
    placementName: string,
    slot: number,
}

const LayoutEmail = ({ settings, slot, emailPopupOffer, prePopulateEmail, isEmbedded, setIsEmail, setSingle, campaignObj, placementId, placementName, templateSettings }: Props) => {

    const refOffers = useRef<HTMLDivElement | null>(null);
    const emailPopupRef = useRef<HTMLDivElement>(null);

    const handleCloseEmailWrapper = () => {
        setIsEmail && setIsEmail(false);
        setSingle && setSingle(undefined);
        window.top?.postMessage({ event: isEmbedded ? 'setEmbeddedIframeSizeBack' : 'setIframeSizeBack', load: '' }, '*');
    }

    const handleClickEmailWrapper = (event: React.MouseEvent<HTMLElement>) => {
        if (event.target instanceof Node && !refOffers.current?.contains(event.target) && !emailPopupRef.current?.contains(event.target)) {
            handleCloseEmailWrapper();
        };
    }

    return <>
        <div id="email-popup-wrapper" onClick={handleClickEmailWrapper} className="email-popup-wrapper">
            <div className={`opl-wrapper opl-wrapper-center`}>
                <div ref={emailPopupRef} className="email-popup">
                    <svg onClick={() => handleCloseEmailWrapper()} className="email-close" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.176625 0.34468C0.385958 0.133389 0.710902 0.109912 0.945926 0.274249L1.02944 0.34468L7.41698 6.79213L13.9706 0.178277C14.2061 -0.0594256 14.5879 -0.0594256 14.8234 0.178277C15.0327 0.389568 15.056 0.717552 14.8932 0.954775L14.8234 1.03907L8.2698 7.65293L14.5195 13.9609C14.755 14.1986 14.755 14.584 14.5195 14.8217C14.3102 15.033 13.9852 15.0565 13.7502 14.8922L13.6667 14.8217L7.41698 8.51372L1.17826 14.8118C0.942765 15.0495 0.560944 15.0495 0.325445 14.8118C0.116112 14.6005 0.0928526 14.2726 0.255667 14.0353L0.325445 13.951L6.56416 7.65293L0.176625 1.20548C-0.0588749 0.967774 -0.0588749 0.582382 0.176625 0.34468Z" fill="#292426" />
                    </svg>
                    <EmailPopup slot={slot} fontFamily={templateSettings ? templateSettings.mainLayout.fontFamily : settings.text.fontFamily} campaignObj={campaignObj} placementId={placementId} placementName={placementName} closeEmailPopup={() => handleCloseEmailWrapper()} offer={emailPopupOffer} automaticallyCheckOptIn={campaignObj.campaignGeneralOptions.automaticallyCheckOptIn} preEmail={prePopulateEmail}></EmailPopup>
                </div>
            </div>
        </div>
    </>
}

export default LayoutEmail;