import { useEffect, useRef, useState } from "react";
import { Grid, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import { postOfferView } from "../../../../services/offers/offers.service";
import "swiper/css";
import "swiper/css/grid";
import {
  CampaignType,
  Offer,
  OffersIdSlots,
  PlacementStage,
  QueryPostOfferView,
  Template,
  TemplateOptionSettings,
} from "../../../../types/global.types";
import OfferItem from "../offer-item/OfferItem.component";

interface Props {
  settings: TemplateOptionSettings;
  settingsTemplate: Template;
  campaign: CampaignType;
  displayOnly: boolean;
  campaignId: string;
  placementId: string;
  placementName: string;
  isInline?: boolean;
  openSingle: (
    o: Offer,
    isSave: boolean,
    isAutoEmail: boolean,
    slot: number
  ) => void;
  onAdd: (offer: Offer, value: boolean, slot: number) => void;
}

const SwiperComponent = ({
  settings,
  campaign,
  displayOnly,
  campaignId,
  placementId,
  placementName,
  settingsTemplate,
  openSingle,
  onAdd,
}: Props) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [eventOfferIds, setEventOfferIds] = useState<OffersIdSlots[]>([]);
  const [eventSentOnIndex, setEventSentOnIndex] = useState<number[]>([]);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [swiperWidth, setSwiperWidth] = useState(0);
  const [paginationNeeded, setPaginationNeeded] = useState(false);

  // swiper item that are fully displayed with margin 20px
  const swiperItemDisplayed = Math.ceil(swiperWidth / 250);

  // swiper item that are fully displayed with last item without margin
  const swiperSize =
    Math.ceil((swiperWidth - swiperItemDisplayed * 20) / 230) * 2;

  const refNavPrev = useRef<HTMLDivElement | null>(null);
  const refNavNext = useRef<HTMLDivElement | null>(null);
  const [previousIndex, setPreviousIndex] = useState<number>(0);

  const setDefaultOfferImpressionEvent = async (offers: OffersIdSlots[]) => {
    if (eventOfferIds.length < campaign.offers.length) {
      const data: QueryPostOfferView = {
        offers,
        campaignId,
        campaignName: campaign.name || "",
        companyId: campaign.company_Id,
        placementId,
        placementName,
        pageUrl: "",
        templateId: settingsTemplate.identifier,
        pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
      };

      await postOfferView(data);
    }
  };

  useEffect(() => {
    // Check if the element is defined
    if (elementRef.current) {
      // Get the width of the element
      setSwiperWidth(elementRef?.current?.offsetWidth);
    }
  }, []);

  //set event for default active slide offers
  useEffect(() => {
    if (swiper && swiperSize !== 0) {
      const _ids = campaign.offers
        .map((offer: Offer, index: number) => ({
          id: offer.id,
          slot: index + 1,
        }))
        .slice(
          0,
          swiperSize !== 0
            ? settingsTemplate.deviceType === "mobile"
              ? 2
              : swiperSize
            : 2
        );
      setDefaultOfferImpressionEvent(_ids);
      setEventOfferIds(_ids);
      setPaginationNeeded(campaign?.offers.length > swiperSize);
    }
    // eslint-disable-next-line
  }, [swiper, swiperSize]);

  const setOfferViewEvent = (activeIndex: number, next: boolean) => {
    const mainIndex = next ? previousIndex + 1 : previousIndex - 1;

    if (activeIndex !== 0 && activeIndex > previousIndex) {
      const sliceFrom = swiperSize * mainIndex;
      const sliceTo = swiperSize * (mainIndex + 1);
      const slot = swiperSize * mainIndex;

      const viewedOffersId = campaign.offers
        .slice(sliceFrom, sliceTo)
        .map((offer: Offer, index: number) => ({
          id: offer.id,
          slot: slot + index + 1,
        }));

      if (!eventSentOnIndex.includes(activeIndex)) {
        setDefaultOfferImpressionEvent(viewedOffersId);
        setEventOfferIds(viewedOffersId);
        setEventSentOnIndex((prevState) => [...prevState, activeIndex]);
      }
    }

    setEventSentOnIndex((prevState) => [...prevState, activeIndex]);
  };

  const setOfferViewEventMobile = (activeIndex: number, next: boolean) => {
    const mainIndex = next ? previousIndex + 1 : previousIndex - 1;

    if (activeIndex !== 0 && activeIndex > previousIndex) {
      const sliceFrom = 2 * mainIndex;
      const sliceTo = 2 * (mainIndex + 1);
      const slot = 2 * mainIndex;

      const viewedOffersId = campaign.offers
        .slice(sliceFrom, sliceTo)
        .map((offer: Offer, index: number) => ({
          id: offer.id,
          slot: slot + index + 1,
        }));

      if (!eventSentOnIndex.includes(activeIndex)) {
        setDefaultOfferImpressionEvent(viewedOffersId);
        setEventOfferIds(viewedOffersId);
        setEventSentOnIndex((prevState) => [...prevState, activeIndex]);
      }
    }

    setEventSentOnIndex((prevState) => [...prevState, activeIndex]);
  };

  return (
    <div ref={elementRef}>
      <Swiper
        className={
          !paginationNeeded
            ? `swiper-container dme6-swiper-container dme6-swiper-container-${settings?.mainLayout?.align} swiper-inline `
            : `swiper-container dme6-swiper-container swiper-inline`
        }
        modules={[Grid, Navigation]}
        grid={{
          rows: 2,
        }}
        slidesPerView={settingsTemplate.deviceType === "mobile" ? 1 : "auto"}
        slidesPerGroup={
          settingsTemplate.deviceType === "mobile" ? 1 : swiperSize / 2
        }
        onSwiper={(s: SwiperClass) => setSwiper(s)}
        navigation={{
          prevEl: refNavPrev.current,
          nextEl: refNavNext.current,
        }}
        onBeforeInit={(swiper: any) => {
          swiper.params.navigation.prevEl = refNavPrev.current;
          swiper.params.navigation.nextEl = refNavNext.current;
        }}
        onSlidePrevTransitionEnd={(_swiper: SwiperClass) => {
          const activeIndex = _swiper.realIndex;
          if (settingsTemplate.deviceType === "mobile") {
            setOfferViewEventMobile(activeIndex, false);
          } else {
            setOfferViewEvent(activeIndex, false);
          }
          setPreviousIndex(previousIndex - 1);
        }}
        onSlideNextTransitionEnd={(_swiper: SwiperClass) => {
          const activeIndex = _swiper.realIndex;
          if (settingsTemplate.deviceType === "mobile") {
            setOfferViewEventMobile(activeIndex, true);
          } else {
            setOfferViewEvent(activeIndex, true);
          }
          setPreviousIndex(previousIndex + 1);
        }}
        preventInteractionOnTransition={
          settingsTemplate.deviceType === "mobile" ? true : false
        }
      >
        {campaign?.offers.length > 0 &&
          campaign?.offers.map((o: Offer, i: number) => {
            return (
              <SwiperSlide
                key={i}
                style={{ color: settings?.dealLayout.textColor }}
              >
                <OfferItem
                  offer={o}
                  slot={i + 1}
                  settings={settings.dealLayout}
                  isAdded={o.isAdded || false}
                  settingsTemplate={settingsTemplate}
                  onAdd={onAdd}
                  displayOnly={displayOnly}
                  campaign={campaign}
                  retailerId={campaign?.company_Id}
                  campaignId={campaignId}
                  placementId={placementId}
                  placementName={placementName}
                  openSingle={openSingle}
                  hasVoucher={campaign.offers.some(
                    (offer) => offer.voucher && offer.voucher.voucherType
                  )}
                />
              </SwiperSlide>
            );
          })}
        <div className="swiper-navigation-dme6">
          <div className="swiper-button-prev" ref={refNavPrev}>
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="18.6094"
                y="23"
                width="8.6192"
                height="1.72384"
                rx="0.86192"
                transform="rotate(-135 18.6094 23)"
                fill={settings.mainLayout.textColor}
              />
              <rect
                x="20.3135"
                y="12.2188"
                width="8.6192"
                height="1.72384"
                rx="0.86192"
                transform="rotate(135 20.3135 12.2188)"
                fill={settings.mainLayout.textColor}
              />
            </svg>
          </div>
          <div className="swiper-button-next" ref={refNavNext}>
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="15.3906"
                y="11"
                width="8.6192"
                height="1.72384"
                rx="0.86192"
                transform="rotate(45 15.3906 11)"
                fill={settings.mainLayout.textColor}
              />
              <rect
                x="14.1719"
                y="22.2656"
                width="8.6192"
                height="1.72384"
                rx="0.86192"
                transform="rotate(-45 14.1719 22.2656)"
                fill={settings.mainLayout.textColor}
              />
            </svg>
          </div>
        </div>
      </Swiper>
    </div>
  );
};

export default SwiperComponent;
