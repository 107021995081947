import React, { useEffect, useRef, useState } from 'react';
import { getTagCampaignOffersFromId, getTrackingEvent, postOfferView } from '../../services/offers/offers.service';
import { CampaignType, Offer, InlineSettings, QueryParams, LayoutDeviceSettings, SelectedOfferStorage, ButtonShapes, QueryPostOfferView, PlacementStage } from '../../types/global.types';
import Item from '../item/item';


interface Props {
    params: QueryParams,
    deviceSettings: LayoutDeviceSettings,
    campaignId: string,
    defaultOpen: boolean,
    placementId: string,
    placementName: string,
    first: boolean,
    setOpen: (open: boolean) => void;
    selectedOffers: string[],
    buttonShape: ButtonShapes
}

const LayoutInline = ({ params, deviceSettings, campaignId, defaultOpen, placementId, placementName, first, setOpen, selectedOffers, buttonShape }: Props) => {
    const [settings] = useState<InlineSettings>(deviceSettings.inline);
    const [loading, isLoading] = useState<boolean>(true);
    const [campaign, setCampaign] = useState<CampaignType>();
    const [single, setSingle] = useState<Offer | undefined>();

    const [isDisplayOnly, setDisplayOnly] = useState<boolean>(false);

    const refOffers = useRef<HTMLDivElement | null>(null);

    const updateCampaignBySingle = (o: Offer) => {
        if (campaign) {
            const newOffers: Offer[] = campaign.offers.map((offer: Offer) => {
                if (offer.id === o.id) {
                    return o
                }
                return offer
            });

            setCampaign(prevState => prevState && { ...prevState, offers: newOffers })
        }
    }


    useEffect(() => {
        window.addEventListener('message', function (event) {

            if (Object.keys(event.data).includes('single')) {
                updateCampaignBySingle(event.data.single);
            }
        })

        return () => {
            window.removeEventListener('message', () => { });
        }
        // eslint-disable-next-line
    }, [campaign])


    const checkSelectedOffers = (selectedOffers: string[] = [], _campaign: CampaignType) => {
        const newOffers: Offer[] = _campaign.offers.map((offer: Offer) => {
            if (selectedOffers.includes(offer.id)) {
                offer.isAdded = true;
            }
            return offer
        });
        setCampaign(prevState => prevState && { ...prevState, offers: newOffers })
    }

    const handleClose = () => {
        window.top?.postMessage({ event: 'closePopupIframe', load: '' }, '*');
        setSingle(undefined);
        setOpen(false);
        campaign && getTrackingEvent({ event: 'ontemplateclose', retailerId: campaign.company_Id, campaignId: placementId, placementName, pltype: isDisplayOnly ? PlacementStage.pre : PlacementStage.post });
    }

    const setSelectedOfferStorage = (storage: SelectedOfferStorage) => {
        window.parent.postMessage({ event: 'setSelectedOffer', load: storage }, '*');
    }

    const openSingle = (offer: Offer, isSave: boolean, isAuto: boolean) => {
        if (isAuto) {
            window.parent.postMessage({ event: 'emailPopup', load: { queryParams: params, offer, isSave, isAuto: isAuto, isDisplayOnly, campaign: campaign, settings: deviceSettings, companyId: campaign?.company_Id, campaignId: campaign?.id, placementId, placementName } }, "*");
        } else {
            window.parent.postMessage({ event: 'openSingle', load: { queryParams: params, offer, isSave, isAuto, isDisplayOnly, campaign: campaign, settings: deviceSettings, companyId: campaign?.company_Id, campaignId: campaign?.id, placementId, placementName } }, "*");
        }
    }

    const handleAdd = (offer: Offer, value: boolean) => {
        setSelectedOfferStorage({ id: offer.id, link: offer.trackingUrl, active: value });
        if (campaign) {
            const newOffers: Offer[] = campaign.offers.map((_offer: Offer) => {
                if (_offer.id === offer.id) {
                    _offer.isAdded = value;
                }
                return _offer
            });
            setCampaign(prevState => prevState && { ...prevState, offers: newOffers })
        }

        value && campaign && getTrackingEvent({ event: 'onselectoffer', offerId: offer.id, retailerId: campaign.company_Id, campaignId: campaign.id, placementId, placementName, pltype: isDisplayOnly ? PlacementStage.pre : PlacementStage.post });
    }

    const getOffersFromCampaignId = async (id: string, data: QueryParams) => {
        const response = await getTagCampaignOffersFromId(id, data);

        if (response?.data?.status) {
            const _campaign: CampaignType = response?.data?.result;
            setCampaign(_campaign);
            setDisplayOnly(_campaign?.placementSettings[0]?.displayOnly);
            checkSelectedOffers(selectedOffers, _campaign);
            _campaign && getTrackingEvent({ event: 'ontemplateopen', retailerId: _campaign.company_Id, campaignId: _campaign.id, placementId, placementName, pltype: isDisplayOnly ? PlacementStage.pre : PlacementStage.post });

            const offerIds = _campaign.offers.map((o: Offer) => o.id);

            const data: QueryPostOfferView = {
                offerIds: offerIds.length > 2 ? offerIds.slice(0, 3) : offerIds,
                campaignId,
                campaignName: _campaign.name || '',
                companyId: _campaign.company_Id,
                placementId,
                placementName,
                pageUrl: '',
                templateId: 'templatUlt',
                pltype: isDisplayOnly ? PlacementStage.pre : PlacementStage.post
            }

            await postOfferView(data);
        } else {
            handleClose();
        }

        isLoading(false);
    }

    useEffect(() => {
        if (params && defaultOpen && campaignId && first) {
            getOffersFromCampaignId(campaignId, params);
        }
        // eslint-disable-next-line
    }, [defaultOpen, params, campaignId, first]);



    // const getEmailPopupStyle = (elementPosition: BoundingClient, iframePosition: BoundingClient): React.CSSProperties => {
    //     const windowWidth = iframeWindowPosition?.width || 0;
    //     const windowHeight = iframeWindowPosition?.height || 0;

    //     const popupWidth = 270;
    //     const popupHeight = 300;

    //     const elementLeftPosition = elementPosition?.left + iframePosition.left + 10;
    //     const elementTopPosition = elementPosition?.top + elementPosition?.height + iframePosition?.top + 10;

    //     const maxWidthEdge = elementLeftPosition + popupWidth;
    //     const maxHeightEdge = elementTopPosition + popupHeight;

    //     var top = 0;
    //     var left = 0;

    //     maxWidthEdge <= windowWidth ? left = elementLeftPosition : left = elementLeftPosition - popupWidth;
    //     maxHeightEdge <= windowHeight ? top = elementTopPosition : top = elementTopPosition - popupHeight;

    //     return {
    //         top,
    //         left
    //     }
    // }

    return <>
        {defaultOpen && !single &&
            <div className={`opl-wrapper opl-wrapper-inline opl-wrapper-image-${settings?.imageRepresentation?.position ? settings?.imageRepresentation?.position : 'top'} ${settings?.minimized ? 'opl-inline-minimized' : ''} ${window.innerWidth < 768 ? 'opl-vertical-order' : ''} ${(campaign?.campaignGeneralOptions.saveOfferOptions && campaign?.campaignGeneralOptions.saveOfferOptions.allowEmail) || isDisplayOnly ? 'opl-email-capture' : ''}`}>
                <>
                    {settings ?
                        <div className={`opl-box opl-box-${settings?.general.align} opl-box-${settings.image.size}`} style={{ backgroundColor: settings?.general.backgroundColor }} ref={refOffers}>
                            {!loading ? <>
                                <div className="opl-inner-box">
                                    <div className="opl-title" style={{
                                        color: settings?.header.textColor,
                                        fontSize: `${settings?.header.fontSize}px`,
                                        fontFamily: settings?.header.fontFamily,
                                        fontWeight: settings?.header.fontWeight,
                                        textTransform: settings?.header.textTransform
                                    }} >
                                        <div className="opl-title-label">
                                            {buttonShape === ButtonShapes.squareImageLock &&
                                                <>
                                                    {isDisplayOnly ?
                                                        <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.77778 9.55V5.75C4.77778 4.49022 5.2753 3.28204 6.16088 2.39124C7.04647 1.50044 8.24759 1 9.5 1C10.7524 1 11.9535 1.50044 12.8391 2.39124C13.7247 3.28204 14.2222 4.49022 14.2222 5.75V9.55M2.88889 9.55H16.1111C17.1543 9.55 18 10.4007 18 11.45V18.1C18 19.1493 17.1543 20 16.1111 20H2.88889C1.84568 20 1 19.1493 1 18.1V11.45C1 10.4007 1.84568 9.55 2.88889 9.55Z" stroke={settings?.header.textColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        :
                                                        <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.77778 9.55228V5.75339C4.7766 4.57578 5.2106 3.43974 5.99552 2.56581C6.78044 1.69188 7.86029 1.1424 9.02542 1.02405C10.1906 0.905704 11.3579 1.22693 12.3007 1.92536C13.2436 2.6238 13.8947 3.64961 14.1278 4.80367M2.88889 9.55306H16.1111C17.1543 9.55306 18 10.4035 18 11.4525V18.1006C18 19.1496 17.1543 20 16.1111 20H2.88889C1.84568 20 1 19.1496 1 18.1006V11.4525C1 10.4035 1.84568 9.55306 2.88889 9.55306Z" stroke={settings?.header.textColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    }
                                                </>
                                            }
                                            {buttonShape === ButtonShapes.squareImage &&
                                                <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" stroke={settings.header.textColor} d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                                                </svg>
                                            }
                                            <span>
                                                {campaign?.placementSettings && campaign?.placementSettings[0]?.promoMessage ? campaign?.placementSettings[0]?.promoMessage : campaign?.description}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="opl-list">
                                        <ul className="opl-ul" style={{ color: settings?.header.textColor }}>
                                            {campaign?.offers?.map((o: Offer, i: number) => {
                                                return <React.Fragment key={`offeritem${i}`} > {i <= Number(settings?.general.offers) - 1 && <Item offer={o} isAdded={o.isAdded || false} settings={settings} onAdd={handleAdd} isInline={true} campaignOptions={campaign.campaignGeneralOptions} isDisplayOnly={isDisplayOnly} retailerId={campaign?.company_Id} campaignId={campaignId} placementId={placementId} placementName={placementName} openSingle={openSingle} />}</React.Fragment>
                                            })}
                                            {campaign && campaign?.offers?.length === 0 && <>No offers available!</>}
                                        </ul>
                                        {isDisplayOnly ? <>
                                            {campaign?.campaignGeneralOptions.emailCaptureOnOffers &&
                                                <div className={`opl-list-info ${isDisplayOnly ? '' : 'opl-list-info-small'}`} style={{ color: settings?.header.textColor, fontFamily: settings.text.fontFamily }}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="12" cy="12" r="12" fill="white" />
                                                        <path d="M18 9.125C18 8.50625 17.46 8 16.8 8H7.2C6.54 8 6 8.50625 6 9.125M18 9.125V15.875C18 16.4938 17.46 17 16.8 17H7.2C6.54 17 6 16.4938 6 15.875V9.125M18 9.125L12 13.0625L6 9.125" stroke="#B0B0B0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>

                                                    <span>Pick your gifts and receive by e-mail after purchase</span>
                                                </div>
                                            }
                                        </>
                                            :
                                            <>
                                                <div className={`opl-list-info opl-list-info-small`} style={{ color: settings?.header.textColor, fontFamily: settings.text.fontFamily }}>
                                                    <span>By selecting the option above you will be directed to a third party website. Any use of third party websites are not subject to our Privacy Policy, and we encourage you to also read their privacy statements</span>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </> :
                                <div className="icon-spin">
                                    <svg className="spin" viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg>
                                </div>
                            }
                        </div> :
                        <div className="opl-box opl-box-nodata">
                            <>
                                No offers available!
                            </>
                            <div className="opl-close" onClick={() => handleClose()}>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.12634 1.12829C1.4276 0.827028 1.91604 0.827028 2.2173 1.12829L6.30039 5.21138L10.3835 1.12829C10.6847 0.827028 11.1732 0.827028 11.4744 1.12829C11.7757 1.42955 11.7757 1.91799 11.4744 2.21925L7.39136 6.30234L11.4744 10.3854C11.7757 10.6867 11.7757 11.1751 11.4744 11.4764C11.1732 11.7777 10.6847 11.7777 10.3835 11.4764L6.30039 7.39331L2.2173 11.4764C1.91604 11.7777 1.4276 11.7777 1.12634 11.4764C0.825075 11.1751 0.825075 10.6867 1.12634 10.3854L5.20943 6.30234L1.12634 2.21925C0.825075 1.91799 0.825075 1.42955 1.12634 1.12829Z" fill="#A3A8B9" />
                                </svg>
                            </div>
                        </div>
                    }
                </>
            </div>
        }
    </>
}

export default LayoutInline;