import React from "react";
import WrapperEmail from "../components/wrapper/wrapper-email";

function EmailPage() {
  return (
    <>
       <WrapperEmail />
    </>
  );
}

export default EmailPage;
