import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";

// default axios config
export const apiConfigTagApi: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_TAGAPI_URL,
    headers: {
        "Content-Type": "application/json",
    }
});

// default axios config
export const apiConfigTrackingApi: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_TRACKAPI_URL,
    headers: {
        "Content-Type": "application/json",
    }
});

const intercaptorRequest = (config: InternalAxiosRequestConfig) => {
    const myUniqueId = sessionStorage.getItem('usersessionid');
    config.headers['usersessionid'] = 'not-set';
    if (myUniqueId) {
        config.headers['usersessionid'] = myUniqueId;
    }
    return config;
};

const interceptorResponse = (config: AxiosResponse) => {
    return config;
};
const interceptorResponseError = async (error: any) => {
    if (error?.response) {
        return error.response;
    }
    return Promise.reject(error);
};

apiConfigTagApi.interceptors.request.use(intercaptorRequest);
apiConfigTagApi.interceptors.response.use(interceptorResponse, interceptorResponseError);

apiConfigTrackingApi.interceptors.request.use(intercaptorRequest);
apiConfigTrackingApi.interceptors.response.use(interceptorResponse, interceptorResponseError);

export default apiConfigTagApi;
