import React from 'react';
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Home from "./pages/Home";
import OfferPage from './pages/Offer';
import './assets/css/style.scss';
import EmailPage from './pages/Email';
import SinglePage from './pages/Single';
import TemplatePage from './pages/Template';
import EmbeddedSinglePage from './pages/EmbeddedSingle';



function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
        <Route path="/offer" element={<OfferPage />} />

        <Route path="/email" element={<EmailPage />} />

        <Route path="/template" element={<TemplatePage />} />

        <Route path="/embedded-single" element={<EmbeddedSinglePage />} />

        <Route path="/single" element={<SinglePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
