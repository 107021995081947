import React, { useEffect, useRef, useState } from 'react';
import { getTagCampaignOffersFromId, getTrackingEvent } from '../../services/offers/offers.service';
import { BoundingClient, ButtonShapes, CampaignType, LayoutClass, Offer, PlacementSettings, PlacementStage, PopupSettings, QueryParams } from '../../types/global.types';
import ExitPopupItem from '../item/exitPopupItem';

interface Props {
    params: QueryParams,
    settings: PopupSettings,
    campaignId: string,
    placementId: string,
    placementName: string,
    first: boolean,
    type?: LayoutClass,
    defaultOpen: boolean,
    setOpen: (open: boolean) => void,
    selectedOffers: string[],
    buttonShape: ButtonShapes,
    prePopulateEmail?: string | undefined,
    emailPopupOffer: Offer | undefined,
    emailPopupPosition?: BoundingClient,
    iframePosition?: BoundingClient,
    iframeWindowPosition?: { width: number, height: number }
}

const LayoutExitPopup = ({ params, settings, campaignId, emailPopupOffer, first, placementId, prePopulateEmail, emailPopupPosition, iframePosition, iframeWindowPosition, buttonShape, placementName, defaultOpen, setOpen, selectedOffers, type = LayoutClass.popup }: Props) => {
    const [loading, isLoading] = useState<boolean>(true);
    const [campaign, setCampaign] = useState<CampaignType>();
    const refOffers = useRef<HTMLDivElement | null>(null);
    const refSingle = useRef<HTMLDivElement | null>(null);


    const handleClose = () => {
        window.top?.postMessage({ event: 'closeExitPopup', load: '' }, '*');
        // setOpen(false);
        // campaign && getTrackingEvent({ event: 'ontemplateclose', retailerId: campaign.company_Id, campaignId, placementId, placementName });
    }

    const handleClickWrapper = (event: React.MouseEvent<HTMLElement>) => {
        if (type !== LayoutClass.action) {
            if (event.target instanceof Node && !refOffers.current?.contains(event.target) && !refSingle.current?.contains(event.target)) {
                handleClose();
            };
        }
    }

    const checkSelectedOffers = (selectedOffers: string[] = [], _campaign: CampaignType) => {
        const newOffers: Offer[] = _campaign.offers.map((offer: Offer) => {
            if (selectedOffers.includes(offer.id)) {
                offer.isAdded = true;
            }
            return offer
        });
        setCampaign(prevState => prevState && { ...prevState, offers: newOffers })
    }

    const getOffersFromCampaignId = async (id: string, data: QueryParams) => {
        const response = await getTagCampaignOffersFromId(id, data);

        if (response?.data?.status) {
            const _campaign: CampaignType = mapCampaignOffers(response?.data?.result);
            setCampaign(_campaign);
            checkSelectedOffers(selectedOffers, _campaign);
            if (_campaign) {
                getTrackingEvent({ event: 'onexitpopup', retailerId: _campaign.company_Id, campaignId: _campaign.id, placementId, placementName, pltype: PlacementStage.post });
            }
        } else {
            handleClose();
        }

        isLoading(false);
    }

    const mapCampaignOffers = (_campaign: CampaignType): CampaignType => {
        const _fixedOffers = _campaign.offers.map((offer: Offer) => offer?.isAdded ? offer : { ...offer, isAdded: false });
        return { ..._campaign, offers: _fixedOffers };
    }

    const getPlacement = (_campaign: CampaignType): PlacementSettings => {
        return _campaign.placementSettings[0]
    }

    useEffect(() => {
        if (params && defaultOpen && campaignId) {
            getOffersFromCampaignId(campaignId, params);
        }
        // eslint-disable-next-line
    }, [defaultOpen, params, campaignId]);

    return <>
        <div onClick={handleClickWrapper} className={`opl-wrapper opl-wrapper-center`}>
            <>
                {settings ?
                    <div ref={refOffers} className={`opl-box`} style={{ backgroundColor: settings?.general.backgroundColor }}>
                        {!loading ? <>
                            <div className="opl-inner-box">
                                <div className="opl-title" style={{
                                    color: settings?.header.textColor,
                                    fontSize: `${settings?.header.fontSize}px`,
                                    fontFamily: settings?.header.fontFamily,
                                    fontWeight: settings?.header.fontWeight,
                                    textTransform: settings?.header.textTransform,
                                    border: "none",
                                }}>

                                    <div className="opl-title-label">
                                        {buttonShape === ButtonShapes.squareImageLock &&
                                            <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.77778 9.55228V5.75339C4.7766 4.57578 5.2106 3.43974 5.99552 2.56581C6.78044 1.69188 7.86029 1.1424 9.02542 1.02405C10.1906 0.905704 11.3579 1.22693 12.3007 1.92536C13.2436 2.6238 13.8947 3.64961 14.1278 4.80367M2.88889 9.55306H16.1111C17.1543 9.55306 18 10.4035 18 11.4525V18.1006C18 19.1496 17.1543 20 16.1111 20H2.88889C1.84568 20 1 19.1496 1 18.1006V11.4525C1 10.4035 1.84568 9.55306 2.88889 9.55306Z" stroke={settings?.header.textColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        }
                                        {buttonShape === ButtonShapes.squareImage &&
                                            <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" stroke={settings.header.textColor} d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                                            </svg>
                                        }
                                        <span>
                                            {campaign && (getPlacement(campaign).promoMessage?.length > 0) ? campaign?.placementSettings[0]?.promoMessage : campaign?.description}
                                        </span>
                                    </div>
                                    <svg onClick={() => handleClose()} className="close-circle" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.12634 1.12829C1.4276 0.827028 1.91604 0.827028 2.2173 1.12829L6.30039 5.21138L10.3835 1.12829C10.6847 0.827028 11.1732 0.827028 11.4744 1.12829C11.7757 1.42955 11.7757 1.91799 11.4744 2.21925L7.39136 6.30234L11.4744 10.3854C11.7757 10.6867 11.7757 11.1751 11.4744 11.4764C11.1732 11.7777 10.6847 11.7777 10.3835 11.4764L6.30039 7.39331L2.2173 11.4764C1.91604 11.7777 1.4276 11.7777 1.12634 11.4764C0.825075 11.1751 0.825075 10.6867 1.12634 10.3854L5.20943 6.30234L1.12634 2.21925C0.825075 1.91799 0.825075 1.42955 1.12634 1.12829Z" fill={settings.header.textColor} />
                                    </svg>
                                </div>
                                <div className="opl-extra-list">
                                    <ul>
                                        {campaign?.offers.map((offer: Offer) => {
                                            return <ExitPopupItem campaign={campaign} key={offer.id} settings={settings} closeExitPopup={() => handleClose()} offer={offer} automaticallyCheckOptIn={campaign.campaignGeneralOptions.automaticallyCheckOptIn} preEmail={prePopulateEmail} />
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </> :
                            <div className="icon-spin">
                                <svg className="spin" viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg>
                            </div>
                        }
                    </div>
                    :
                    <div className="opl-box opl-box-nodata">
                        <>
                            No offers available!
                        </>
                        <div className="opl-close" onClick={() => handleClose()}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.12634 1.12829C1.4276 0.827028 1.91604 0.827028 2.2173 1.12829L6.30039 5.21138L10.3835 1.12829C10.6847 0.827028 11.1732 0.827028 11.4744 1.12829C11.7757 1.42955 11.7757 1.91799 11.4744 2.21925L7.39136 6.30234L11.4744 10.3854C11.7757 10.6867 11.7757 11.1751 11.4744 11.4764C11.1732 11.7777 10.6847 11.7777 10.3835 11.4764L6.30039 7.39331L2.2173 11.4764C1.91604 11.7777 1.4276 11.7777 1.12634 11.4764C0.825075 11.1751 0.825075 10.6867 1.12634 10.3854L5.20943 6.30234L1.12634 2.21925C0.825075 1.91799 0.825075 1.42955 1.12634 1.12829Z" fill="#A3A8B9" />
                            </svg>
                        </div>
                    </div>
                }
            </>
        </div>

    </>
}

export default LayoutExitPopup;