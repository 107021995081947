import React, { useState } from "react";
import {
  getTrackingEvent,
  getCustomVoucher,
} from "../../../../services/offers/offers.service";
import {
  CampaignType,
  DealLayoutSettings,
  Offer,
  OffersIdSlots,
  PlacementStage,
  RedeemBy,
  Template,
  VoucherType,
} from "../../../../types/global.types";
import offerImageSrc from "./../../../../assets/images/offer-listening.png";
interface Props {
  offer: Offer;
  eventOfferIds: OffersIdSlots[];
  settings: DealLayoutSettings;
  settingsTemplate: Template;
  displayOnly: boolean;
  retailerId: string;
  campaignId: string;
  placementId: string;
  placementName: string;
  campaign: CampaignType;
  openSingle: (
    o: Offer,
    isSave: boolean,
    isAutoEmail: boolean,
    slot: number,
    isShare?: boolean
  ) => void;
  onAdd: (offer: Offer, value: boolean, slot: number) => void;
}

const OfferItem = ({
  offer,
  eventOfferIds,
  settings,
  campaign,
  settingsTemplate,
  displayOnly,
  retailerId,
  campaignId,
  placementId,
  placementName,
  openSingle,
  onAdd,
}: Props) => {
  const [copyCode, setCopyCode] = useState<boolean>(false);
  const [getCode, setGetCode] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);
  const [customVoucher, setCustomVoucher] = useState<string>("");
  const [voucherLoading, setVoucherLoading] = useState<boolean>(false);

  const getOfferSlotById = (
    offerId: string,
    offerIdAndSlots: OffersIdSlots[]
  ): number => offerIdAndSlots.find((f) => f.id === offerId)?.slot || 0;

  const handleAdd = (e: React.ChangeEvent<HTMLInputElement>, offer: Offer) => {
    const slot = getOfferSlotById(offer.id, eventOfferIds);
    const value = e.target.checked;
    if (displayOnly) {
      onAdd(offer, value, slot);
      value && handleOpenEmailInput(offer);
    }
  };

  const handleOpenEmailInput = (offer: Offer) => {
    if (
      campaign.campaignGeneralOptions?.showAddToOrder &&
      campaign.campaignGeneralOptions.emailCaptureOnOffers &&
      campaign.campaignGeneralOptions.emailCaptureOnAddToOrder
    ) {
      const slot = getOfferSlotById(offer.id, eventOfferIds) || 0;
      openSingle(offer, false, true, slot);
    }
  };

  const handleGetGift = (
    event: React.MouseEvent<HTMLElement>,
    url: string,
    slot: number,
    offer: Offer
  ) => {
    if (offer?.voucher?.voucherType === VoucherType.CUSTOM && !getCode) {
      return;
    }
    
    if (
      event.target instanceof HTMLElement &&
      !event.target.classList.contains("compact-item-info-icon") &&
      !event.target.classList.contains("compact-item-share-icon") &&
      !event.target.classList.contains("compact-item-email-icon") &&
      !event.target.classList.contains("copy-box") &&
      !event.target.classList.contains("voucher-code") &&
      !displayOnly &&
      offer.offerSettings.redeemBy === RedeemBy.webemail
    ) {
      window.open(
        url + `&tid=${settingsTemplate.identifier}&slot=${slot}`,
        "_blank"
      );
    }
  };

  const handleClickInfo = (event: React.MouseEvent, offer: Offer) => {
    event.preventDefault();
    const slot = getOfferSlotById(offer.id, eventOfferIds);
    openSingle(offer, false, false, slot);
    getTrackingEvent({
      event: "onreadmoreclick",
      offerId: offer.id,
      retailerId,
      campaignId,
      placementId,
      placementName,
      tid: settingsTemplate.identifier,
      slot,
      pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
    });
  };

  const handleClickShare = (event: React.MouseEvent, offer: Offer) => {
    event.preventDefault();
    const slot = getOfferSlotById(offer.id, eventOfferIds);
    openSingle(offer, false, false, slot, true);
  };

  const handleSaveEmail = (event: React.MouseEvent, offer: Offer) => {
    event.preventDefault();
    const slot = getOfferSlotById(offer.id, eventOfferIds);
    openSingle(offer, true, false, slot);
  };

  const handleCopyHtml = (offerVoucher: string) => {
    window.focus();
    navigator.clipboard?.writeText(
      customVoucher ? customVoucher : offerVoucher
    );
    setCopied(true);
    setCopyCode(false);
    setTimeout(() => {
      setCopied(false);
    }, 1000); // Show the message for 1 second
  };

  const handleGetCode = async () => {
    setVoucherLoading(true);
    const customVoucher = await getCustomVoucher({
      offerId: offer.id,
      campaignId: campaign.id,
    });
    setCustomVoucher(customVoucher?.data?.result);
    setGetCode(true);
    setVoucherLoading(false);
  };

  const getVoucherText = () => {
    return customVoucher ? (
      <>
        <span style={{ fontWeight: "400", marginRight: "3px" }}>Code: </span>

        <span style={{ fontWeight: "600" }}>{customVoucher}</span>
      </>
    ) : offer.voucher?.code ? (
      <>
        <span style={{ fontWeight: "400", marginRight: "3px" }}>Code: </span>
        <span style={{ fontWeight: "600" }}>{offer.voucher?.code}</span>
      </>
    ) : (
      <span style={{ fontWeight: "400" }}>Sorry, code just expired!</span>
    );
  };

  return (
    <div
      key={`compact-${offer.id}`}
      onClick={(e) =>
        handleGetGift(
          e,
          offer.trackingUrl,
          getOfferSlotById(offer.id, eventOfferIds),
          offer
        )
      }
      className={`swiper-compact-item ${
        displayOnly && campaign.campaignGeneralOptions.showAddToOrder
          ? "display"
          : "getbutton"
      }`}
    >
      <input
        className="input"
        id={offer.id}
        type="checkbox"
        disabled={
          !displayOnly || !campaign.campaignGeneralOptions.showAddToOrder
        }
        checked={offer.isAdded || false}
        onChange={(e) => handleAdd(e, offer)}
      />
      <label
        htmlFor={offer.id}
        className="compact-item-layout"
        style={{
          backgroundColor: settings?.backgroundColor,
          fontSize: settings?.fontSize,
          color: settings?.textColor,
          border: offer.badge?.useBorder
            ? `1px solid ${offer.badge?.backgroundColor}`
            : "1px solid transparent",
          position: "relative",
        }}
      >
        <span
          style={{
            position: "absolute",
            top: "-8.5px",
            left: "10px",
            fontSize: "10px",
            color: offer.badge?.fontColor,
            backgroundColor: offer.badge?.backgroundColor,
            padding: "0 6px",
            borderRadius: "3px",
            fontWeight: "600",
          }}
          className="badge-settings"
        >
          {offer.badge?.name}
        </span>
        <div className="compact-item-image">
          <img
            src={offer.imageUrl ? offer.imageUrl : offerImageSrc}
            alt={offer.title}
          />
        </div>

        <div
          className={`compact-item-content`}
          style={{
            marginBottom: offer.voucher.voucherType ? "39px" : "0",
          }}
        >
          <div className="compact-item-title">
            <span
              className={`compact-item-title-text max-2lines `}
              style={{
                color: settings?.textColor,
                fontSize: `${Number(settings?.fontSize) + 1}px`,
              }}
            >
              {offer.title}
            </span>

            <div className="compact-item-icons">
              <div
                className="compact-item-info-icon"
                onClick={(e) => handleClickInfo(e, offer)}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 0.882353C3.84517 0.882353 0.882353 3.84517 0.882353 7.5C0.882353 11.1548 3.84517 14.1176 7.5 14.1176C11.1548 14.1176 14.1176 11.1548 14.1176 7.5C14.1176 3.84517 11.1548 0.882353 7.5 0.882353ZM8.02941 5.73529V11.9118H7.04118V5.73529H8.02941ZM7.53529 3.11647C7.94294 3.11647 8.28882 3.45 8.28882 3.85765C8.28882 4.26529 7.94294 4.61118 7.53529 4.61118C7.12765 4.61118 6.79412 4.26529 6.79412 3.85765C6.79412 3.45 7.12765 3.11647 7.53529 3.11647Z"
                    fill={settings?.textColor}
                  />
                </svg>
              </div>

              {!displayOnly &&
                campaign.sharingOptions?.allowSharing &&
                campaign.sharingOptions.sharingPlatforms?.platforms?.length >
                  0 &&
                offer?.offerSettings?.allowShare && (
                  <div
                    className="compact-item-share-icon"
                    onClick={(e) => handleClickShare(e, offer)}
                  >
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="share-alt"
                      width="1em"
                      height="1em"
                      fill={settings?.textColor}
                      aria-hidden="true"
                    >
                      <path d="M752 664c-28.5 0-54.8 10-75.4 26.7L469.4 540.8a160.68 160.68 0 000-57.6l207.2-149.9C697.2 350 723.5 360 752 360c66.2 0 120-53.8 120-120s-53.8-120-120-120-120 53.8-120 120c0 11.6 1.6 22.7 4.7 33.3L439.9 415.8C410.7 377.1 364.3 352 312 352c-88.4 0-160 71.6-160 160s71.6 160 160 160c52.3 0 98.7-25.1 127.9-63.8l196.8 142.5c-3.1 10.6-4.7 21.8-4.7 33.3 0 66.2 53.8 120 120 120s120-53.8 120-120-53.8-120-120-120zm0-476c28.7 0 52 23.3 52 52s-23.3 52-52 52-52-23.3-52-52 23.3-52 52-52zM312 600c-48.5 0-88-39.5-88-88s39.5-88 88-88 88 39.5 88 88-39.5 88-88 88zm440 236c-28.7 0-52-23.3-52-52s23.3-52 52-52 52 23.3 52 52-23.3 52-52 52z"></path>
                    </svg>
                  </div>
                )}
            </div>
          </div>

          <div className="compact-item-get">
            <div className="compact-item-get-center">
              {displayOnly ? (
                <>
                  <div className="switch-wrapper">
                    {campaign.campaignGeneralOptions.showAddToOrder && (
                      <label htmlFor={offer.id}>
                        <span className="switch-toggle">
                          <span className="switch-toggle-before"></span>
                        </span>

                        <span
                          className="switch-text"
                          style={{
                            fontWeight: settings?.buttonFontWeight,
                            fontSize: `${settings?.buttonFontSize}px`,
                          }}
                        >
                          <span className="checked-text">Selected</span>
                          <span className="unchecked-text">Select</span>
                        </span>
                      </label>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {offer.offerSettings.redeemBy === RedeemBy.email ? (
                    offer?.voucher?.voucherType === VoucherType.CUSTOM &&
                    !getCode ? (
                      <div
                        style={{
                          fontSize: `${settings?.buttonFontSize}px`,
                          position: "relative",
                        }}
                        className="compact-item-action-get dashed-button-c13 copy-box"
                        onClick={handleGetCode}
                      >
                        {voucherLoading ? (
                          <svg
                            className="spin"
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                        ) : (
                          "Get code"
                        )}
                      </div>
                    ) : (
                      <div
                        className="compact-item-action-get"
                        onClick={(e) => handleSaveEmail(e, offer)}
                        style={{
                          backgroundColor: settings?.emailButtonBackgroundColor,
                          color: settings?.emailButtonTextColor,
                          marginRight: "0",
                          fontSize: "14px",
                          border: `1px solid transparent`,
                          borderRadius: `${settings?.buttonRounding}px`,
                        }}
                      >
                        <svg
                          style={{
                            marginRight: "5px",
                            minWidth: "14px",
                            verticalAlign: "middle",
                          }}
                          width="14"
                          height="11"
                          viewBox="0 0 14 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13 2.125C13 1.50625 12.46 1 11.8 1H2.2C1.54 1 1 1.50625 1 2.125M13 2.125V8.875C13 9.49375 12.46 10 11.8 10H2.2C1.54 10 1 9.49375 1 8.875V2.125M13 2.125L7 6.0625L1 2.125"
                            stroke={settings?.emailButtonTextColor}
                            strokeWidth="1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        {offer.offerCTAText}
                      </div>
                    )
                  ) : offer?.voucher?.voucherType === VoucherType.CUSTOM &&
                    !getCode ? (
                    <div
                      className="compact-item-action-get dashed-button-c13 copy-box"
                      onClick={handleGetCode}
                      style={{
                        fontSize: `${settings?.buttonFontSize}px`,
                      }}
                    >
                      {voucherLoading ? (
                        <svg
                          className="spin"
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      ) : (
                        "Get code"
                      )}
                    </div>
                  ) : (
                    <div
                      className="compact-item-action-get button max-2lines"
                      style={{
                        color: settings?.buttonTextColor,
                        fontSize: `${settings?.buttonFontSize}px`,
                        fontWeight: settings?.buttonFontWeight,
                        backgroundColor: settings?.buttonBackgroundColor,
                        borderRadius: `${settings?.buttonRounding}px`,
                        border: `1px solid ${
                          settings?.buttonBorderColor ||
                          settings?.buttonBackgroundColor
                        }`,
                      }}
                    >
                      {offer.offerCTAText || "Get offer now"}
                    </div>
                  )}
                </>
              )}

              {!displayOnly &&
                campaign.campaignGeneralOptions.saveOfferOptions
                  .allowSavingOffers &&
                campaign.campaignGeneralOptions.saveOfferOptions.allowEmail &&
                offer.offerSettings.redeemBy === RedeemBy.webemail && (
                  <span
                    className="compact-item-email-icon"
                    onClick={(e) => handleSaveEmail(e, offer)}
                    style={{
                      backgroundColor:
                        settings?.emailButtonBackgroundColor || "#aaaaaa",
                      color: settings?.emailButtonTextColor,
                      border: `1px solid transparent`,
                      borderRadius: `${settings?.buttonRounding}px`,
                    }}
                  >
                    <svg
                      width="14"
                      height="11"
                      viewBox="0 0 14 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 2.125C13 1.50625 12.46 1 11.8 1H2.2C1.54 1 1 1.50625 1 2.125M13 2.125V8.875C13 9.49375 12.46 10 11.8 10H2.2C1.54 10 1 9.49375 1 8.875V2.125M13 2.125L7 6.0625L1 2.125"
                        stroke={settings?.emailButtonTextColor || "#f2f2f2"}
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                )}
            </div>
          </div>
        </div>
        {offer.voucher?.voucherType && (
          <span
            style={{
              position: "absolute",
              bottom: "0",
              left: "0",
              fontSize: "11px",
              color: settings?.buttonTextColor,
              backgroundColor: settings?.buttonBackgroundColor,
              borderRadius: "0 0 5px 5px",
              fontWeight: "600",
              textAlign: "center",
              width: "100%",
              height: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onMouseEnter={() => setCopyCode(true)}
            onMouseLeave={() => {
              setCopyCode(false);
            }}
            onClick={() => handleCopyHtml(offer.voucher?.code)}
            className="voucher-code"
          >
            {offer.voucher?.voucherType === VoucherType.CUSTOM && !getCode ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
                className="copy-box"
              >
                Code:
                <svg
                  style={{
                    marginLeft: "5px",
                  }}
                  width="70"
                  height="16"
                  viewBox="0 0 70 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="70" height="16" fill="" />
                  <circle
                    cx="4"
                    cy="8"
                    r="2"
                    fill={settings?.buttonTextColor || "white"}
                  />
                  <circle
                    cx="10"
                    cy="8"
                    r="2"
                    fill={settings?.buttonTextColor || "white"}
                  />
                  <circle
                    cx="52"
                    cy="8"
                    r="2"
                    fill={settings?.buttonTextColor || "white"}
                  />
                  <circle
                    cx="58"
                    cy="8"
                    r="2"
                    fill={settings?.buttonTextColor || "white"}
                  />
                  <circle
                    cx="16"
                    cy="8"
                    r="2"
                    fill={settings?.buttonTextColor || "white"}
                  />
                  <circle
                    cx="22"
                    cy="8"
                    r="2"
                    fill={settings?.buttonTextColor || "white"}
                  />
                  <circle
                    cx="28"
                    cy="8"
                    r="2"
                    fill={settings?.buttonTextColor || "white"}
                  />
                  <circle
                    cx="34"
                    cy="8"
                    r="2"
                    fill={settings?.buttonTextColor || "white"}
                  />
                  <circle
                    cx="40"
                    cy="8"
                    r="2"
                    fill={settings?.buttonTextColor || "white"}
                  />
                  <circle
                    cx="46"
                    cy="8"
                    r="2"
                    fill={settings?.buttonTextColor || "white"}
                  />
                </svg>
              </div>
            ) : (
              <>
                {copyCode ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      fontWeight: "400",
                    }}
                    onClick={() => handleCopyHtml(offer.voucher?.code)}
                    className="copy-box"
                  >
                    {copied ? (
                      <>
                        <svg
                          width="8"
                          height="6"
                          viewBox="0 0 8 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            marginRight: "5px",
                          }}
                        >
                          <path
                            d="M1 3L3 5L7 1"
                            stroke={settings?.buttonTextColor || "white"}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Copied successfully
                      </>
                    ) : (
                      <>
                        <svg
                          width="11"
                          height="10"
                          viewBox="0 0 11 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            marginRight: "5px",
                          }}
                        >
                          <path
                            d="M7.75 3V2C7.75 1.73478 7.63147 1.48043 7.42049 1.29289C7.20952 1.10536 6.92337 1 6.625 1H2.125C1.82663 1 1.54048 1.10536 1.3295 1.29289C1.11853 1.48043 1 1.73478 1 2V6C1 6.26522 1.11853 6.51957 1.3295 6.70711C1.54048 6.89464 1.82663 7 2.125 7H3.25M4.375 3H8.875C9.49632 3 10 3.44772 10 4V8C10 8.55228 9.49632 9 8.875 9H4.375C3.75368 9 3.25 8.55228 3.25 8V4C3.25 3.44772 3.75368 3 4.375 3Z"
                            stroke={settings?.buttonTextColor || "white"}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Copy Code
                      </>
                    )}
                  </div>
                ) : copied ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      fontWeight: "400",
                    }}
                    className="copy-box"
                  >
                    <svg
                      width="8"
                      height="6"
                      viewBox="0 0 8 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        marginRight: "5px",
                      }}
                    >
                      <path
                        d="M1 3L3 5L7 1"
                        stroke={settings?.buttonTextColor || "white"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Copied successfully
                  </div>
                ) : (
                  getVoucherText()
                )}
              </>
            )}
          </span>
        )}
      </label>
    </div>
  );
};

export default OfferItem;
