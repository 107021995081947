import React, { useRef, useState } from "react";
import offerImageSrc from "../../assets/images/offer-listening.png";
import { getTrackingEvent } from "../../services/offers/offers.service";
import { BoundingClient, CampaignOptions, InlineSettings, Offer, PlacementStage, PopupSettings } from "../../types/global.types";

interface Props {
  offer: Offer,
  settings: PopupSettings | InlineSettings,
  isDisplayOnly: boolean | undefined,
  isAdded: boolean,
  retailerId: string,
  campaignId: string,
  placementId: string,
  placementName: string,
  campaignOptions: CampaignOptions,
  isInline?: boolean,
  openSingle: (o: Offer, isSave: boolean, isAutoEmail: boolean, coordinates?: BoundingClient) => void,
  onAdd: (offer: Offer, value: boolean) => void,
}

const Item = ({ offer, settings, campaignOptions, isDisplayOnly, isInline = false, retailerId, campaignId, placementId, placementName, isAdded, openSingle, onAdd }: Props) => {
  const listRef = useRef<HTMLLIElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [hover, setHover] = useState(false);

  const handleAdd = (e: React.ChangeEvent<HTMLInputElement>) => {
    isDisplayOnly && setLoading(true);
    const value = e.target.checked;
    if (isDisplayOnly) {
      onAdd(offer, value);
      value && handleOpenEmailInput();
    }

    setTimeout(() => {
      setLoading(false);
    }, 0.4e3)
  }

  const handleOpenEmailInput = () => {
    if (campaignOptions?.showAddToOrder && campaignOptions?.emailCaptureOnOffers && campaignOptions?.emailCaptureOnAddToOrder) {
      openSingle(offer, false, true);
    }
  }

  const handleReadMore = (o: Offer, isSave: boolean, isAutoEmail: boolean) => {
    openSingle(o, isSave, isAutoEmail);
    getTrackingEvent({ event: isSave ? 'onoffersaveclick' : 'onreadmoreclick', offerId: offer.id, retailerId, campaignId, placementId, placementName, pltype: isDisplayOnly ? PlacementStage.pre : PlacementStage.post });
  }

  const handleGetGift = (url: string) => {
    window.open(url, '_blank');
  }

  const handleClickInfo = (event: React.MouseEvent) => {
    event.preventDefault();
    openSingle(offer, false, false);
    getTrackingEvent({ event: 'onreadmoreclick', offerId: offer.id, retailerId, campaignId, placementId, placementName, pltype: isDisplayOnly ? PlacementStage.pre : PlacementStage.post });
  }

  const handleBoxClick = (event: any) => {
    if (!event.target?.classList.contains('ign-icon') &&
      !isDisplayOnly) {
      handleGetGift(offer.trackingUrl);
    }
  }

  return (
    <>
      <li ref={listRef} className={`opl-list-item opl-item-${settings.image.size} ${isDisplayOnly ? 'opl-item-display' : 'opl-item-getbutton'}`}>
        {loading &&
          <div className="loader-absolute-li">
            <div className="icon-spin">
              <svg className="spin" viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg>
            </div>
          </div>
        }

        <input className="opl-input" id={offer.id} type="checkbox" checked={isAdded} onChange={handleAdd} />
        <label htmlFor={offer.id} onClick={handleBoxClick} className="opl-item-layout">

          <div className="opl-item-image">
            <img src={offer.imageUrl ? offer.imageUrl : offerImageSrc} alt={offer.title} className={`image-${settings.image.size}`} style={{ borderRadius: `${settings.image.rounding}%` }} />
          </div>

          <div className={`opl-item-content opl-content-${settings.image.size}`}>
            <div className="opl-item-title">
              <span className={`opl-item-title-text ${settings?.minimized ? 'opl-max-2lines' : 'opl-max-1lines'}`} style={{
                color: settings.text.textColor,
                fontSize: `${settings.text.fontSize}px`,
                fontFamily: settings.text.fontFamily,
                fontWeight: settings.text.fontWeight,
                textTransform: settings.text.textTransform
              }}>
                {offer.title}
              </span>
              {/* <svg onClick={handleClickInfo} className="icon-circle-info" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 10.1V7.5M7.5 4.9H7.5065M14 7.5C14 11.0899 11.0899 14 7.5 14C3.91015 14 1 11.0899 1 7.5C1 3.91015 3.91015 1 7.5 1C11.0899 1 14 3.91015 14 7.5Z" stroke={settings.text.textColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg> */}
              <div className="icon-circle-info ign-icon" onClick={handleClickInfo}>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 0.882353C3.84517 0.882353 0.882353 3.84517 0.882353 7.5C0.882353 11.1548 3.84517 14.1176 7.5 14.1176C11.1548 14.1176 14.1176 11.1548 14.1176 7.5C14.1176 3.84517 11.1548 0.882353 7.5 0.882353ZM8.02941 5.73529V11.9118H7.04118V5.73529H8.02941ZM7.53529 3.11647C7.94294 3.11647 8.28882 3.45 8.28882 3.85765C8.28882 4.26529 7.94294 4.61118 7.53529 4.61118C7.12765 4.61118 6.79412 4.26529 6.79412 3.85765C6.79412 3.45 7.12765 3.11647 7.53529 3.11647Z" fill={settings.text.textColor} />
                </svg>
              </div>
            </div>
            {!(settings?.minimized && isInline) &&
              <div className="opl-item-description opl-max-2lines" style={{
                color: settings.text.textColor,
                fontSize: `${settings.text.fontSize}px`,
                fontFamily: settings.text.fontFamily,
                fontWeight: settings.text.fontWeight,
                textTransform: settings.text.textTransform
              }}>{offer.description}</div>
            }

            {!settings?.minimized &&
              <div className={`opl-item-get ${!isDisplayOnly ? 'space-between' : ''}`}>
                {/* <div className="opl-item-button" onClick={() => handleReadMore(offer, false, true)} style={{ color: settings.text.textColor }} >Read more</div> */}

                {!isDisplayOnly && campaignOptions?.saveOfferOptions.allowSavingOffers && campaignOptions?.saveOfferOptions.allowEmail &&
                  <div className="action-save ign-icon" onClick={() => handleReadMore(offer, true, false)} style={{ color: settings.text.textColor }}>
                    <svg width="12" height="14" viewBox="0 0 12 14" fill={"none"} xmlns="http://www.w3.org/2000/svg">
                      <path d="M11 13L6 9.66667L1 13V2.33333C1 1.97971 1.15051 1.64057 1.41842 1.39052C1.68633 1.14048 2.04969 1 2.42857 1H9.57143C9.95031 1 10.3137 1.14048 10.5816 1.39052C10.8495 1.64057 11 1.97971 11 2.33333V13Z" stroke={settings.text.textColor} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Save offer
                  </div>
                }
              </div>
            }

            {!isDisplayOnly ?
              <>
                <div className="opl-item-get">
                  <div className="action-get button"
                    onMouseEnter={() => {
                      setHover(true);
                    }}
                    onMouseLeave={() => {
                      setHover(false);
                    }}
                    style={{
                      color: settings.getButton.textColor,
                      fontSize: `${settings.getButton.fontSize}px`,
                      fontFamily: settings.getButton.fontFamily,
                      fontWeight: settings.getButton.fontWeight,
                      backgroundColor: hover ? `${settings.getButton?.borderColor || settings.getButton.textColor}40` : settings.getButton.backgroundColor,
                      borderRadius: settings.getButton.rounding,
                      border: `2px solid ${settings.getButton?.borderColor || settings.getButton.backgroundColor}`,
                      textTransform: settings.getButton?.textTransform || 'initial'
                    }} onClick={() => handleGetGift(offer.trackingUrl)}>
                    {offer?.offerCTAText || 'Get offer now'}
                  </div>
                  {settings?.minimized && campaignOptions?.saveOfferOptions.allowSavingOffers && campaignOptions?.saveOfferOptions.allowEmail &&
                    <div className="action-save" onClick={() => handleReadMore(offer, true, false)} style={{ color: settings.text.textColor }} >
                      <svg width="12" height="14" viewBox="0 0 12 14" fill={"none"} xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 13L6 9.66667L1 13V2.33333C1 1.97971 1.15051 1.64057 1.41842 1.39052C1.68633 1.14048 2.04969 1 2.42857 1H9.57143C9.95031 1 10.3137 1.14048 10.5816 1.39052C10.8495 1.64057 11 1.97971 11 2.33333V13Z" stroke={settings.text.textColor} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                  }
                </div>
              </>
              :
              <>
                {campaignOptions?.showAddToOrder &&
                  <div className="opl-item-get">
                    <div className="opl-switch-wrapper">
                      <label htmlFor={offer.id}>
                        <span className="opl-switch-toggle" >
                          <span className="opl-switch-toggle-before"></span>
                        </span>
                        <span className="opl-switch-text" style={{
                          color: settings.text.textColor,
                          fontSize: `${settings.text.fontSize}px`,
                          fontFamily: settings.text.fontFamily,
                          fontWeight: settings.text.fontWeight,
                          textTransform: settings.text.textTransform
                        }}>
                          <span className="checked-text">
                            Selected
                          </span>
                          <span className="unchecked-text">
                            Select
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                }
              </>
            }
          </div>

          <div className="opl-item-actions">
            <div className="icon-circle-info ign-icon" onClick={handleClickInfo}>

              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 0.882353C3.84517 0.882353 0.882353 3.84517 0.882353 7.5C0.882353 11.1548 3.84517 14.1176 7.5 14.1176C11.1548 14.1176 14.1176 11.1548 14.1176 7.5C14.1176 3.84517 11.1548 0.882353 7.5 0.882353ZM8.02941 5.73529V11.9118H7.04118V5.73529H8.02941ZM7.53529 3.11647C7.94294 3.11647 8.28882 3.45 8.28882 3.85765C8.28882 4.26529 7.94294 4.61118 7.53529 4.61118C7.12765 4.61118 6.79412 4.26529 6.79412 3.85765C6.79412 3.45 7.12765 3.11647 7.53529 3.11647Z" fill={settings.text.textColor} />
              </svg>
            </div>
            {!isDisplayOnly && campaignOptions?.saveOfferOptions.allowSavingOffers && campaignOptions?.saveOfferOptions.allowEmail &&
              <div className="icon-circle-info ign-icon" onClick={() => handleReadMore(offer, true, false)} >
                <svg style={{ color: settings.text.textColor }} className="save-icon ign-icon" width="12" height="14" viewBox="0 0 12 14" fill={"none"} xmlns="http://www.w3.org/2000/svg">
                  <path d="M11 13L6 9.66667L1 13V2.33333C1 1.97971 1.15051 1.64057 1.41842 1.39052C1.68633 1.14048 2.04969 1 2.42857 1H9.57143C9.95031 1 10.3137 1.14048 10.5816 1.39052C10.8495 1.64057 11 1.97971 11 2.33333V13Z" stroke={settings.text.textColor} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            }
          </div>

        </label>
      </li>
    </>
  );
}

export default Item;
