import { useEffect, useRef, useState } from "react"
import { Navigation } from "swiper/modules"
import { Swiper, SwiperSlide, SwiperClass } from "swiper/react"
import { postOfferView } from "../../../../services/offers/offers.service"
import { CampaignType, Offer, OffersIdSlots, PlacementStage, QueryPostOfferView, Template, TemplateOptionSettings } from "../../../../types/global.types"
import OfferItem from "../offer-item/OfferItem.component"

interface Props {
    settings: TemplateOptionSettings,
    settingsTemplate: Template,
    offersCount: string,
    campaign: CampaignType,
    displayOnly: boolean,
    campaignId: string,
    placementId: string,
    defaultOpen: boolean,
    placementName: string,
    isInline?: boolean,
    preEmail?: string,
    setPreEmail?: (email: string) => void,
    openSingle: (o: Offer, isSave: boolean, isAutoEmail: boolean, slot: number) => void,
    onAdd: (offer: Offer, value: boolean, slot: number) => void,
}

const SwiperComponent = ({ settings, setPreEmail, offersCount, preEmail, campaign, displayOnly, defaultOpen, campaignId, placementId, placementName, settingsTemplate, openSingle, onAdd }: Props) => {
    const [swiper, setSwiper] = useState<SwiperClass | null>(null);
    const [eventOfferIds, setEventOfferIds] = useState<OffersIdSlots[]>([]);

    const refNavPrev = useRef<HTMLDivElement | null>(null);
    const refNavNext = useRef<HTMLDivElement | null>(null);

    const setDefaultOfferImpressionEvent = async (offers: OffersIdSlots[]) => {
        if (eventOfferIds.length < campaign.offers.length) {
            const data: QueryPostOfferView = {
                offers,
                campaignId,
                campaignName: campaign.name || '',
                companyId: campaign.company_Id,
                placementId,
                placementName,
                pageUrl: '',
                templateId: settingsTemplate.identifier,
                pltype: displayOnly ? PlacementStage.pre : PlacementStage.post
            }

            await postOfferView(data);
        }
    }

    //set event for default active slide offers
    useEffect(() => {
        if (!defaultOpen) {
            setEventOfferIds([]);
        }
        // eslint-disable-next-line
    }, [defaultOpen])

    //set event for default active slide offers
    useEffect(() => {
        if (swiper && defaultOpen) {
            const _ids = campaign.offers.map((offer: Offer, index: number) => ({ id: offer.id, slot: index + 1 })).slice(0, Number(settingsTemplate.offerCount || 1));
            setDefaultOfferImpressionEvent(_ids);
            setEventOfferIds(_ids);
        }
        // eslint-disable-next-line
    }, [swiper, defaultOpen])

    return <>
        {campaign?.offers.length > 0 &&
            <Swiper
                className={`swiper-container`}
                modules={[Navigation]}
                slidesPerView={offersCount}
                spaceBetween={20}
                loop={true}
                onSwiper={(s: SwiperClass) => setSwiper(s)}
                navigation={{
                    prevEl: refNavPrev.current,
                    nextEl: refNavNext.current,
                }}
                onBeforeInit={(swiper: any) => {
                    swiper.params.navigation.prevEl = refNavPrev.current;
                    swiper.params.navigation.nextEl = refNavNext.current;
                }}
                onSlidePrevTransitionEnd={(_swiper: SwiperClass) => {
                    const acitveIndex = _swiper.realIndex;
                    const offersLength = campaign.offers.length;
                    if (offersLength >= acitveIndex - 1) {
                        const offerIds = eventOfferIds.map((value: OffersIdSlots) => value.id);
                        if (!offerIds.includes(campaign.offers[acitveIndex].id)) {
                            setDefaultOfferImpressionEvent([{ id: campaign.offers[acitveIndex].id, slot: acitveIndex + 1 }]);
                            setEventOfferIds(prevState => ([...prevState, { id: campaign.offers[acitveIndex].id, slot: acitveIndex + 1 }]))
                        }
                    }
                }}
                onSlideNextTransitionEnd={(_swiper: SwiperClass) => {
                    const acitveIndex = _swiper.realIndex;
                    const offersLength = campaign.offers.length;
                    const offerCount = Number(settingsTemplate.offerCount);

                    if (acitveIndex === offersLength - 1) {
                        const offerIds = eventOfferIds.map((value: OffersIdSlots) => value.id);
                        if (!offerIds.includes(campaign.offers[0].id)) {
                            setDefaultOfferImpressionEvent([{ id: campaign.offers[0].id, slot: 1 }]);
                            setEventOfferIds(prevState => ([...prevState, { id: campaign.offers[0].id, slot: 1 }]))
                        }
                    }

                    if (offersLength > acitveIndex + offerCount - 1) {
                        const offerIds = eventOfferIds.map((value: OffersIdSlots) => value.id);
                        if (!offerIds.includes(campaign.offers[acitveIndex + offerCount - 1].id)) {
                            setDefaultOfferImpressionEvent([{ id: campaign.offers[acitveIndex + offerCount - 1].id, slot: acitveIndex + offerCount }]);
                            setEventOfferIds(prevState => ([...prevState, { id: campaign.offers[acitveIndex + offerCount - 1].id, slot: acitveIndex + offerCount }]));
                        }
                    }
                }}
            >
                {campaign?.offers.map((o: Offer, i: number) => {
                    return <SwiperSlide key={i} style={{ color: settings?.dealLayout.textColor }}>
                        <OfferItem
                            offer={o}
                            slot={i + 1}
                            settings={settings.dealLayout}
                            isAdded={o.isAdded || false}
                            settingsTemplate={settingsTemplate}
                            onAdd={onAdd} displayOnly={displayOnly}
                            campaign={campaign}
                            retailerId={campaign?.company_Id}
                            campaignId={campaignId}
                            placementId={placementId}
                            placementName={placementName}
                            openSingle={openSingle}
                            preEmail={preEmail}
                            setPreEmail={setPreEmail}
                        />
                    </SwiperSlide>
                })}
                <div className="swiper-button-prev" ref={refNavPrev}>
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="17" cy="17" r="17" transform="rotate(-180 17 17)" fill={settings.dealLayout.backgroundColor} />
                        <rect x="18.6094" y="23" width="8.6192" height="1.72384" rx="0.86192" transform="rotate(-135 18.6094 23)" fill={settings.dealLayout.textColor} />
                        <rect x="20.3135" y="12.2188" width="8.6192" height="1.72384" rx="0.86192" transform="rotate(135 20.3135 12.2188)" fill={settings.dealLayout.textColor} />
                    </svg>
                </div>
                <div className="swiper-button-next" ref={refNavNext}>
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="17" cy="17" r="17" fill={settings.dealLayout.backgroundColor} />
                        <rect x="15.3906" y="11" width="8.6192" height="1.72384" rx="0.86192" transform="rotate(45 15.3906 11)" fill={settings.dealLayout.textColor} />
                        <rect x="14.1719" y="22.2656" width="8.6192" height="1.72384" rx="0.86192" transform="rotate(-45 14.1719 22.2656)" fill={settings.dealLayout.textColor} />
                    </svg>
                </div>
            </Swiper>
        }
    </>
}


export default SwiperComponent