import { useEffect, useState } from "react";
import { BoundingClient, LayoutClass, LayoutDeviceSettings, Offer, QueryParams, QueryPostEmail } from "../../types/global.types";
import LayoutInline from "../layout/inline";
import LayoutPopup from "../layout/popup";
import LayoutExitPopup from "../layout/exitPopup";
import { postEmail } from "../../services/offers/offers.service";

const Wrapper = () => {
    const [params, setParams] = useState<QueryParams>();
    const [settings, setSettings] = useState<LayoutDeviceSettings>();
    const [isOpen, setOpen] = useState<boolean>(false);
    const [campaignId, setCampaignId] = useState<string>();
    const [placementId, setPlacementId] = useState<string>();
    const [placementName, setPlacementName] = useState<string>("");
    const [first, setFirst] = useState<boolean>(true);
    const [sessionId, setSessionId] = useState<string>('');

    const [selectedOffers, setSelectedOffers] = useState<string[]>([]);

    const [emailPopupOffer, setEmailPopupOffer] = useState<Offer | undefined>(undefined);
    const [preEmail, setPreEmail] = useState<string>();

    const [isExtraIframe, setExtraIframe] = useState<boolean>(false);
    const [iframePosition, setIframePosition] = useState<BoundingClient>();
    const [iframeWindowPosition, setIframeWindowPosition] = useState<{ width: number, height: number }>();

    const [isExitPopup, setIsExitPopup] = useState<boolean>(false);

    const [emailPopupPosition, setEmailPopupPosition] = useState<BoundingClient>();

    const [emailPostParams, setEmailPostParams] = useState<QueryPostEmail>();


    useEffect(() => {

        window.addEventListener('message', function (event) {
            if (Object.keys(event.data).includes('id')) {
                setCampaignId(event.data?.id);
            }
            if (Object.keys(event.data).includes('options')) {
                setSettings(event.data?.options);
            }
            if (Object.keys(event.data).includes('open')) {
                setOpen(event.data?.open);
            }
            if (Object.keys(event.data).includes('queryParams')) {
                setParams(event?.data.queryParams);
            }
            if (Object.keys(event.data).includes('first')) {
                setFirst(event?.data.first);
            }
            if (Object.keys(event.data).includes('sessionId')) {
                setSessionId(event?.data.sessionId);
            }
            if (Object.keys(event.data).includes('selectedOffers')) {
                setSelectedOffers(event?.data.selectedOffers);
            }
            if (Object.keys(event.data).includes('placementId')) {
                setPlacementId(event?.data.placementId);
            }
            if (Object.keys(event.data).includes('placementName')) {
                setPlacementName(event?.data.placementName);
            }
            if (Object.keys(event.data).includes('emailPopup')) {
                setEmailPopupOffer(event?.data.emailPopup);
            }

            if (Object.keys(event.data).includes('placementName')) {
                setPlacementName(event?.data.placementName);
            }

            if (Object.keys(event.data).includes('postEmail')) {
                setEmailPostParams(event?.data.postEmail);
            }

            if (Object.keys(event.data).includes('exit')) {
                setIsExitPopup(true);
                setParams(event?.data.exit.queryParams);
                setCampaignId(event.data.exit.campaignId);
                setPlacementName(event?.data.exit.placementName);
                setPlacementId(event?.data.exit.placementId);
                setSettings(event?.data.exit.settings);
                event.data.exit?.preEmail && setPreEmail(event.data.exit?.preEmail);
                setOpen(true);
            }

            if (Object.keys(event.data).includes('extra')) {
                setExtraIframe(true);
                setSettings(event?.data.extra.settings);
                setParams(event?.data.extra.queryParams);
                setCampaignId(event.data.extra.campaignId);
                setPlacementName(event?.data.extra.placementName);
                setPlacementId(event?.data.extra.placementId);
                setIframePosition(event?.data.extra.iframePosition);
                setIframeWindowPosition(event?.data.extra.iframeWindowPosition);
                setEmailPopupOffer(event?.data.extra.offer);
                setEmailPopupPosition(event.data.extra?.coordinates);

                event.data.extra?.isEmail && setExtraIframe(false);
                event.data.extra?.open && setOpen(true);
                event.data.extra?.preEmail && setPreEmail(event.data.extra?.preEmail);
            }
        });

        return () => window.removeEventListener('message', () => { });
    });

    const postEmailMethod = async (post: QueryPostEmail) => {
        const response = await postEmail(post);
        if (response?.data?.status) {
            window.top?.postMessage({ event: 'clearPostEmailStorage', load: '' }, '*');
        }
    }

    useEffect(() => {
        if (emailPostParams?.companyId && settings?.template) {
            postEmailMethod({ ...emailPostParams, pltype: settings?.template.stage });
        }
        // eslint-disable-next-line
    }, [emailPostParams]);

    useEffect(() => {
        if (sessionId) {
            sessionStorage.setItem('usersessionid', sessionId);
        }
    }, [sessionId]);

    return <>

        {params && campaignId && placementId && settings &&
            <>
                {isExitPopup ? <>
                    <LayoutExitPopup
                        params={params}
                        settings={settings?.type === LayoutClass.action ? settings.action : settings.popup}
                        placementId={placementId}
                        placementName={placementName}
                        buttonShape={settings.button.shape}
                        campaignId={campaignId}
                        selectedOffers={selectedOffers}
                        first={first}
                        prePopulateEmail={preEmail}
                        emailPopupOffer={emailPopupOffer}
                        iframePosition={iframePosition}
                        iframeWindowPosition={iframeWindowPosition}
                        emailPopupPosition={emailPopupPosition}
                        defaultOpen={isOpen}
                        setOpen={setOpen}
                    />
                </>
                    :
                    <>
                        {(settings?.type === LayoutClass.button || settings?.type === LayoutClass.action) &&
                            <LayoutPopup
                                params={params}
                                type={settings.type}
                                settings={settings?.type === LayoutClass.action ? settings.action : settings.popup}
                                placementId={placementId}
                                placementName={placementName}
                                buttonShape={settings.button.shape}
                                campaignId={campaignId}
                                selectedOffers={selectedOffers}
                                first={first}
                                defaultOpen={isOpen}
                                setOpen={setOpen}
                            />}

                        {settings?.type === LayoutClass.inline && !isExtraIframe &&
                            <LayoutInline
                                params={params}
                                deviceSettings={settings}
                                placementId={placementId}
                                buttonShape={settings.button.shape}
                                placementName={placementName}
                                campaignId={campaignId}
                                selectedOffers={selectedOffers}
                                first={first}
                                defaultOpen={isOpen}
                                setOpen={setOpen}
                            />}

                    </>
                }
            </>
        }

        {/* {isExtraIframe && settings && single && campaignId && companyId && placementId && placementName && campaignObj &&
            <SingleOffer
                deviceSettings={settings.inline}
                offer={single}
                placementId={placementId}
                placementName={placementName}
                campaignObj={campaignObj}
                isSave={isSingleSave}
                isAuto={isSingleAuto}
                isDisplayOnly={isDisplayOnly}
                campaignId={campaignId}
                preEmail={params?.contactDetails ? params.contactDetails[0]?.email : undefined}
                companyId={companyId}
            />
        } */}
    </>

}

export default Wrapper