import { useEffect, useRef, useState } from "react";
import { getTrackingEvent } from "../../services/offers/offers.service";
import { AutoEmailStorage, CampaignType, Offer, PlacementStage } from "../../types/global.types";
import { EMAIL_REGEX } from "../../utility/regexp";


interface Props {
    preEmail?: string,
    automaticallyCheckOptIn: boolean,
    fontFamily: string,
    offer: Offer,
    campaignObj: CampaignType,
    placementId: string,
    placementName: string,
    closeEmailPopup: () => void,
    slot: number
}

const EmailPopup = ({ preEmail, automaticallyCheckOptIn, fontFamily, offer, campaignObj, placementId, placementName, closeEmailPopup, slot }: Props) => {
    const [email, setEmail] = useState<string | undefined>(preEmail);
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [validEmail, setValidEmail] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const refAutoEmail = useRef<HTMLInputElement | null>(null);
    const [emailSwitch, setEmailSwitch] = useState<boolean>(preEmail || automaticallyCheckOptIn ? true : false);

    const submitAutoEmail = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (offer && email && validEmail) {
            setLoading(true);

            getTrackingEvent({ event: 'onsendpreemailclick', retailerId: campaignObj.company_Id, campaignId: campaignObj.id, placementId, placementName, offerId: offer.id, slot, pltype: PlacementStage.pre });

            const _autoEmailItem: AutoEmailStorage = {
                offerLink: offer.trackingUrl,
                offerId: offer.id,
                email: email
            }

            setAutoEmailStorage(_autoEmailItem);

            setTimeout(() => {
                setEmailSent(true);
                setEmail(undefined);
                setLoading(false);
            }, 1e3);

            setTimeout(() => {
                closeEmailPopup();
            }, 2e3);
        }

    }

    useEffect(() => {
        if (refAutoEmail.current) {
            refAutoEmail.current.focus();
        }
    }, []);

    // Validate email on change
    useEffect(() => {
        if (email) {
            const isValid = EMAIL_REGEX.test(email);
            setValidEmail(isValid);
        }
        // eslint-disable-next-line 
    }, [email]);

    const setAutoEmailStorage = (storage: AutoEmailStorage) => {
        window.top?.postMessage({ event: 'setAutoEmail', load: storage }, '*');
    }

    return <>
        <div className="content-panel">
            <form className={`${isLoading ? 'loading-overlay' : ''}`} onSubmit={submitAutoEmail}>
                {isLoading &&
                    <div className="icon-spin">
                        <svg className="spin" viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg>
                    </div>
                }
                <div className="content-panel-email-input" style={{ fontFamily: fontFamily }}>
                    <div className="content-panel-input-title">
                        Enter your email
                    </div>
                    <div className="content-panel-input-description">
                        We will email you after you make a purchase
                    </div>
                    <div className="content-panel-input">
                        <input type="text" ref={refAutoEmail} onChange={(e) => setEmail(e.target.value)} value={email || ''} placeholder="Enter your email..." />
                    </div>
                    <div className="label-wrapper">
                        {(emailSent && typeof email === "undefined") &&
                            <span className="label-success"> Email will be sent automatically</span>
                        }
                        {!validEmail && email && (
                            <span className="label-error">Must be valid email!</span>
                        )}
                    </div>
                </div>

                <div className="content-panel-submit" style={{ fontFamily: fontFamily }}>
                    <div className="submit-checkbox">
                        <label className="label-message" htmlFor="accept-email">
                            <input id="accept-email" type="checkbox" checked={emailSwitch} onChange={(e) => setEmailSwitch(e.target.checked)} />
                            <div className="checkboxsvg"></div>

                            I have read and agree to the <a href="https://brandswap.com/privacy/" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
                        </label>
                    </div>
                    <button type="submit" className="button" disabled={!validEmail || !emailSwitch}>Save</button>
                </div>
            </form>
        </div>
    </>
}

export default EmailPopup