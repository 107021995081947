import { AxiosResponse } from "axios";
import {
  ApiResponse,
  QueryCustomVoucher,
  QueryParams,
  QueryPostEmail,
  QueryPostOfferView,
  QuerySaveEmail,
  TrackingParams,
  TrackingShare,
} from "../../types/global.types";
import apiConfigTagApi, { apiConfigTrackingApi } from "../apiConfig";

export async function getTagCampaignOffersFromId(
  id: string,
  data: QueryParams
): Promise<void | AxiosResponse<ApiResponse, any>> {
  return await apiConfigTagApi
    .post<ApiResponse>(`/tag/campaigns/offers/${id}`, data)
    .catch((error) => {
      console.log(error);
    });
}

export async function postTagEmailOffer(
  data: QuerySaveEmail
): Promise<void | AxiosResponse<ApiResponse, any>> {
  return await apiConfigTrackingApi
    .post<ApiResponse>(`/tracking/emailoffer`, data)
    .catch((error) => {
      console.log(error);
    });
}

export async function postOfferView(
  data: QueryPostOfferView
): Promise<void | AxiosResponse<ApiResponse, any>> {
  return await apiConfigTrackingApi
    .post<ApiResponse>(`/tracking/offerview`, data)
    .catch((error) => {
      console.log(error);
    });
}

export async function postTrackingShare(
  data: TrackingShare
): Promise<void | AxiosResponse<ApiResponse, any>> {
  return await apiConfigTrackingApi
    .post<ApiResponse>(`/tracking/share`, data)
    .catch((error) => {
      console.log(error);
    });
}

export async function postEmail(
  data: QueryPostEmail
): Promise<void | AxiosResponse<ApiResponse, any>> {
  return await apiConfigTrackingApi
    .post<ApiResponse>(`/tracking/emailonpost`, data)
    .catch((error) => {
      console.log(error);
    });
}

export async function getTrackingEvent(
  data: TrackingParams
): Promise<void | AxiosResponse<ApiResponse, any>> {
  const url = new URL(`${process.env.REACT_APP_TRACKAPI_URL}/tracking`);

  for (const [key, value] of Object.entries(data)) {
    value && url.searchParams.set(key, value);
  }

  return await apiConfigTagApi
    .get<ApiResponse>(url.toString())
    .catch((error) => {
      console.log(error);
    });
}

export async function getCustomVoucher(
  data: QueryCustomVoucher
): Promise<void | AxiosResponse<ApiResponse, any>> {
  return await apiConfigTrackingApi
    .get<ApiResponse>(
      `/tracking/voucher/custom/${data.offerId}/${data.campaignId}`
    )
    .catch((error) => {
      console.log(error);
    });
}
