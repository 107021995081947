import React, { useEffect, useRef } from 'react';

const LayoutEmailError = () => {

    const refOffers = useRef<HTMLDivElement | null>(null);

    const emailPopupRef = useRef<HTMLDivElement>(null);

    const handleCloseEmailWrapper = () => {
        window.top?.postMessage({ event: 'setIframeSizeBack', load: '' }, '*');
    }

    const handleClickEmailWrapper = (event: React.MouseEvent<HTMLElement>) => {
        if (event.target instanceof Node && !refOffers.current?.contains(event.target) && !emailPopupRef.current?.contains(event.target)) {
            handleCloseEmailWrapper();
        };
    }

    useEffect(() => {
        const _initClosing = setTimeout(() => {
            window.top?.postMessage({ event: 'setIframeSizeBack', load: '' }, '*');
        }, 1e3);

        return () => clearTimeout(_initClosing);

    }, [])

    return <>
        <div id="email-popup-wrapper" onClick={handleClickEmailWrapper} className="email-popup-wrapper error-popup-wrapper">

            <div className={`opl-wrapper opl-wrapper-center`}>
                <div ref={emailPopupRef} className="email-popup">

                    <div className="content-panel">

                        <div className="error-popup">
                            <svg onClick={() => handleCloseEmailWrapper()} className="email-close" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.12634 1.12829C1.4276 0.827028 1.91604 0.827028 2.2173 1.12829L6.30039 5.21138L10.3835 1.12829C10.6847 0.827028 11.1732 0.827028 11.4744 1.12829C11.7757 1.42955 11.7757 1.91799 11.4744 2.21925L7.39136 6.30234L11.4744 10.3854C11.7757 10.6867 11.7757 11.1751 11.4744 11.4764C11.1732 11.7777 10.6847 11.7777 10.3835 11.4764L6.30039 7.39331L2.2173 11.4764C1.91604 11.7777 1.4276 11.7777 1.12634 11.4764C0.825075 11.1751 0.825075 10.6867 1.12634 10.3854L5.20943 6.30234L1.12634 2.21925C0.825075 1.91799 0.825075 1.42955 1.12634 1.12829Z" fill="#A3A8B9" />
                            </svg>


                            <h4>Something went wrong</h4>
                            <span onClick={handleCloseEmailWrapper}>Close now</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>
}

export default LayoutEmailError;