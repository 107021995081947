import React, { useCallback, useEffect, useState } from "react";
import LayoutEmail from "../components/layout/email";
import TemplateSingle from "../components/templates/single/TemplateSingle.component";
import { postEmail } from "../services/offers/offers.service";
import { CampaignType, LayoutDeviceSettings, Offer, PlacementStage, QueryPostEmail, SelectedOfferStorage } from "../types/global.types";

function EmbeddedSinglePage() {
  const [settings, setSettings] = useState<LayoutDeviceSettings>();
  const [campaign, setCampaign] = useState<CampaignType>();
  const [placementId, setPlacementId] = useState<string>();
  const [placementName, setPlacementName] = useState<string>("");
  const [offerSlot, setOfferSlot] = useState<number>(0);
  const [singleOffer, setSingleOffer] = useState<Offer | undefined>();
  const [preEmail, setPreEmail] = useState<string>();
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const [emailPostParams, setEmailPostParams] = useState<QueryPostEmail>();
  const [sessionId, setSessionId] = useState<string>('');
  const [isSave, setSave] = useState<boolean>(false);
  const [isShare, setShare] = useState<boolean>(false);

  useEffect(() => {

    window.addEventListener('message', function (event) {
      if (Object.keys(event.data).includes('options')) {
        setSettings(event.data?.options);
      }
      if (Object.keys(event.data).includes('isSave')) {
        setSave(event.data?.isSave);
      }
      if (Object.keys(event.data).includes('isShare')) {
        setShare(event.data?.isShare);
      }
      if (Object.keys(event.data).includes('campaign')) {
        setCampaign(event.data?.campaign);
      }
      if (Object.keys(event.data).includes('sessionId')) {
        setSessionId(event?.data.sessionId);
      }
      if (Object.keys(event.data).includes('placementId')) {
        setPlacementId(event?.data.placementId);
      }
      if (Object.keys(event.data).includes('placementName')) {
        setPlacementName(event?.data.placementName);
      }

      if (Object.keys(event.data).includes('placementName')) {
        setPlacementName(event?.data.placementName);
      }

      if (Object.keys(event.data).includes('postEmail')) {
        setEmailPostParams(event?.data.postEmail);
      }

      if (Object.keys(event.data).includes('slot')) {
        setOfferSlot(event?.data.slot);
      }

      if (Object.keys(event.data).includes('offer')) {
        setSingleOffer(event?.data.offer);
      }

      if (Object.keys(event.data).includes('isEmail')) {
        setIsEmail(event?.data.isEmail);
      }

      if (Object.keys(event.data).includes('preEmail')) {
        setPreEmail(event?.data.preEmail);
      }
      if (Object.keys(event.data).includes('settings')) {
        setSettings(event?.data.settings);
      }
    });
  }, [])

  useEffect(() => {

    return () => window.removeEventListener('message', () => { });
  }, []);

  const postEmailMethod = async (post: QueryPostEmail) => {
    const response = await postEmail(post);
    if (response?.data?.status) {
      window.top?.postMessage({ event: 'clearPostEmailStorage', load: '' }, '*');
    }
  }

  useEffect(() => {
    if (emailPostParams?.companyId && settings?.template) {
      emailPostParams.contactDetails.length > 0 && setPreEmail(emailPostParams.contactDetails[0].email);
      postEmailMethod({ ...emailPostParams, templateId: settings?.template.identifier, pltype: PlacementStage.pre });
    }
    // eslint-disable-next-line
  }, [emailPostParams]);

  useEffect(() => {
    if (sessionId) {
      sessionStorage.setItem('usersessionid', sessionId);
    }
  }, [sessionId]);

  const setSelectedOfferStorage = (storage: SelectedOfferStorage) => {
    window.top?.postMessage({ event: 'setSelectedOffer', load: storage }, '*');
  }

  const handleCloseSingle = () => {
    setSingleOffer(undefined);
  }

  const getTemplateComponent = useCallback(() => {
    let elm: React.JSX.Element = <></>;

    const handleAdd = (offer: Offer, value: boolean, slot: number) => {
      setSelectedOfferStorage({ id: offer.id, link: offer.trackingUrl, active: value });
      if (campaign) {
        const newOffers: Offer[] = campaign.offers.map((_offer: Offer) => {
          if (_offer.id === offer.id) {
            _offer.isAdded = value;
          }
          return _offer
        });
        setCampaign(prevState => prevState && { ...prevState, offers: newOffers })
      } else {
        const _single = { ...singleOffer, isAdded: value };
        setSingleOffer(prevState => prevState && ({ ...prevState, isAdded: value }));
        window.top?.postMessage({ event: 'singleChange', offer: _single, slot }, '*');
      }

    }

    if (campaign?.id && settings?.settings && singleOffer && placementId) {

      if (isEmail) {
        elm = <LayoutEmail
          settings={settings.popup}
          templateSettings={settings.settings}
          emailPopupOffer={singleOffer}
          isEmbedded={true}
          setIsEmail={setIsEmail}
          setSingle={setSingleOffer}
          prePopulateEmail={preEmail}
          campaignObj={campaign}
          campaignId={campaign.id}
          placementId={placementId}
          placementName={placementName}
          slot={offerSlot}
        ></LayoutEmail>
      } else {
        elm = <TemplateSingle
          slot={offerSlot}
          settings={settings.settings}
          isInline={true}
          offer={singleOffer}
          isSave={isSave}
          isShare={isShare}
          placementId={placementId}
          placementName={placementName}
          settingsTemplate={settings.template}
          campaignObj={campaign}
          displayOnly={settings.template.stage === PlacementStage.pre}
          campaignId={campaign?.id}
          companyId={campaign.company_Id}
          updateCampaign={handleAdd}
          setPreEmail={setPreEmail}
          preEmail={preEmail}
          closeSignle={handleCloseSingle}
        />;
      }
    }

    return elm;
  }, [campaign, settings, singleOffer, placementId, isEmail, preEmail, isSave, isShare, offerSlot, placementName])


  return (
    <>
      {getTemplateComponent()}
    </>
  );
}

export default EmbeddedSinglePage;
