import { useEffect, useRef, useState } from "react";
import { getTrackingEvent, postTagEmailOffer } from "../../services/offers/offers.service";
import { CampaignType, InlineSettings, Offer, PlacementStage, PopupSettings, QuerySaveEmail } from "../../types/global.types";
import { EMAIL_REGEX } from "../../utility/regexp";

interface Props {
    preEmail?: string,
    automaticallyCheckOptIn: boolean,
    settings: InlineSettings | PopupSettings,
    offer: Offer,
    campaign: CampaignType,
    closeExitPopup: () => void
}

const ExitPopupItem = ({ preEmail, automaticallyCheckOptIn, settings, campaign, offer, closeExitPopup }: Props) => {
    const [email, setEmail] = useState<string | undefined>(preEmail);
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [validEmail, setValidEmail] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const refAutoEmail = useRef<HTMLInputElement | null>(null);
    const [hover, setHover] = useState(false);

    const submitAutoEmail = async (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (offer && email && validEmail) {
            setLoading(true);

            const _queryEmail: QuerySaveEmail = {
                offerLink: offer.trackingUrl,
                offerid: offer.id,
                email: email,
                companyId: campaign.company_Id,
                placementId: campaign.placementSettings[0]?.id,
                campaignId: campaign.id || '',
                slot: 0,
                orderId: offer.orderId,
                pltype: PlacementStage.post
            }
            getTrackingEvent({ event: 'onexitemailclick', retailerId: campaign.company_Id, campaignId: campaign.id, offerId: offer.id, placementId: campaign.placementSettings[0]?.id, placementName: campaign.placementSettings[0]?.name, pltype: PlacementStage.post });

            const response = await postTagEmailOffer(_queryEmail);

            if (response?.data?.status) {
                setTimeout(() => {
                    setEmailSent(true);
                    setEmail(undefined);
                    setLoading(false);
                }, 1e3);
            }

            setTimeout(() => {
                // closeExitPopup();
            }, 2e3);
        }

    }

    useEffect(() => {
        if (refAutoEmail.current) {
            window.innerWidth > 768 && refAutoEmail.current.focus();
        }
    }, []);

    // Validate email on change
    useEffect(() => {
        if (email) {
            const isValid = EMAIL_REGEX.test(email);
            setValidEmail(isValid);
        }
        // eslint-disable-next-line 
    }, [email]);


    const handleGetGift = (url: string) => {
        getTrackingEvent({ event: 'onexitgetclick', retailerId: campaign.company_Id, campaignId: campaign.id, offerId: offer.id, placementId: campaign.placementSettings[0]?.id, placementName: campaign.placementSettings[0]?.name, pltype: PlacementStage.post });
        window.open(url, '_blank');
    }

    return <>
        <li key={offer.id} style={{ backgroundColor: '#fff', borderRadius: '6px' }}>

            <div className="opl-extra-list-image">
                <img src={offer.imageUrl ? offer.imageUrl : ''} alt={offer.title} className={`image-${settings.image.size}`} style={{ borderRadius: `${settings.image.rounding}%` }} />
            </div>
            <div className="opl-extra-list-content">

                <div className="opl-extra-list-content-title" style={{ color: settings.text.textColor, fontFamily: settings.text.fontFamily }}>
                    {offer.title}
                </div>
                <div className="opl-extra-list-content-inputs">

                    <div className="opl-extra-list-content-email">

                        <div className="opl-extra-info" style={{ fontFamily: settings.text.fontFamily }}>
                            <input className="default-input" type="text" placeholder="Enter your email..." onChange={(e) => setEmail(e.target.value)} value={email || ''} style={{ borderColor: settings?.text.textColor, borderRadius: '3px', color: settings.text.textColor }} />
                            <div className="opl-info-label" style={{ color: settings.text.textColor }}>
                                {(emailSent && typeof email === "undefined") &&
                                    <span className="label-success"> Email will be sent automatically</span>
                                }
                                {!validEmail && email && (
                                    <span className="label-error">Must be valid email!</span>
                                )}
                            </div>
                        </div>
                        <div className={`action-get button ${isLoading || !validEmail ? 'action-get-disabled' : ''}`}
                            onClick={submitAutoEmail}
                            style={{
                                backgroundColor: settings.getButton.textColor,
                                fontFamily: settings.getButton.fontFamily,
                                fontWeight: settings.getButton.fontWeight,
                                color: settings.getButton.backgroundColor,
                                borderRadius: settings.getButton.rounding,
                                textTransform: settings.getButton?.textTransform || 'initial'
                            }}>
                            Send gift as email
                        </div>

                        {isLoading &&
                            <div className="icon-spin">
                                <svg className="spin" viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg>
                            </div>
                        }
                    </div>
                    <div className="opl-extra-list-content-getbutton">
                        <div className="action-get button" onClick={() => handleGetGift(offer.trackingUrl)}
                            onMouseEnter={() => {
                                setHover(true);
                            }}
                            onMouseLeave={() => {
                                setHover(false);
                            }}
                            style={{
                                color: settings.getButton.textColor,
                                fontFamily: settings.getButton.fontFamily,
                                fontWeight: settings.getButton.fontWeight,
                                backgroundColor: hover ? `${settings.getButton?.borderColor || settings.getButton.textColor}40` : settings.getButton.backgroundColor,
                                borderRadius: settings.getButton.rounding,
                                border: `2px solid ${settings.getButton?.borderColor || settings.getButton.backgroundColor}`,
                                textTransform: settings.getButton?.textTransform || 'initial'
                            }}>
                            {offer?.offerCTAText || 'Get offer now'}
                        </div>
                    </div>

                </div>
                <div className="opl-extra-list-content-footer" style={{ color: settings.text.textColor, fontFamily: settings.text.fontFamily }}>
                    By clicking "Send gift as email" I agreed to our <a href="https://brandswap.com/privacy/" rel="noopener noreferrer" target="_blank" style={{ color: settings?.text.textColor }}>Privacy Policy</a>
                </div>
            </div>
        </li>
    </>
}

export default ExitPopupItem