import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  getTrackingEvent,
  postTagEmailOffer,
} from "../../services/offers/offers.service";
import {
  Offer,
  QuerySaveEmail,
  InlineSettings,
  PopupSettings,
  SinglePanels,
  AutoEmailStorage,
  CampaignType,
  SelectedOfferStorage,
  PlacementStage,
} from "../../types/global.types";
import { EMAIL_REGEX } from "../../utility/regexp";

interface Props {
  deviceSettings: InlineSettings | PopupSettings;
  offer: Offer;
  isSave: boolean;
  isAuto: boolean;
  placementId: string;
  placementName: string;
  isDisplayOnly: boolean;
  closeSignle?: () => void;
  campaignId: string;
  companyId: string;
  preEmail?: string;
  campaignObj: CampaignType;
  isInline: boolean;
  setPreEmail?: (email: string) => void;
  updateCampaign?: (offer: Offer, value: boolean) => void;
}

const SingleOffer = ({
  deviceSettings,
  offer,
  isSave,
  isAuto,
  isDisplayOnly,
  isInline,
  campaignObj,
  closeSignle,
  campaignId,
  companyId,
  updateCampaign,
  setPreEmail,
  placementId,
  placementName,
  preEmail,
}: Props) => {
  const [panel, setPanel] = useState<SinglePanels>(
    isAuto && isDisplayOnly ? SinglePanels.autoEmail : SinglePanels.overview
  );
  const [single, setSingle] = useState<Offer | undefined>(offer);
  const [isSingleSave, setSingleSave] = useState<boolean>(isSave);
  const refSingle = useRef<HTMLDivElement | null>(null);
  const refAutoEmail = useRef<HTMLInputElement | null>(null);
  const refSaveEmail = useRef<HTMLInputElement | null>(null);
  const [emailSwitch, setEmailSwitch] = useState<boolean>(
    preEmail || campaignObj.campaignGeneralOptions.automaticallyCheckOptIn
      ? true
      : false
  );
  const [email, setEmail] = useState<string | undefined>(preEmail || undefined);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [hover, setHover] = useState(false);

  const handleClose = async () => {
    setSingle(undefined);
    await getTrackingEvent({
      event: "onreadmoreclose",
      offerId: offer.id,
      retailerId: companyId,
      campaignId: campaignId,
      placementId,
      placementName,
      pltype: isDisplayOnly ? PlacementStage.pre : PlacementStage.post,
    });
    closeSignle && closeSignle();
    window.top?.postMessage({ event: "closePopupIframe", load: "" }, "*");
  };

  const handleCloseSingle = () => {
    handleClose();
  };

  const handleClickSingleWrapper = (event: React.MouseEvent<HTMLElement>) => {
    if (
      event.target instanceof Node &&
      !refSingle.current?.contains(event.target)
    ) {
      handleCloseSingle();
    }
  };

  const handleAdd = (offer: Offer, value: boolean) => {
    const _single = { ...offer, isAdded: value };
    updateCampaign && updateCampaign(offer, value);
    setSingle((prevState) => prevState && { ...prevState, isAdded: value });
    window.top?.postMessage({ event: "singleChange", offer: _single }, "*");

    if (value) {
      campaignObj.campaignGeneralOptions?.emailCaptureOnOffers &&
        setPanel(SinglePanels.autoEmail);

      setTimeout(() => {
        window.innerWidth > 768 && refAutoEmail.current?.focus();
      });
    }

    isInline &&
      setSelectedOfferStorage({
        id: offer.id,
        link: offer.trackingUrl,
        active: value,
      });
  };

  const handleGetGift = (url: string) => {
    window.open(url, "_blank");
  };

  const handleToggleSave = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setSingleSave((pS) => !pS);
    setEmail(undefined);
    setEmailSent(false);
    setEmailSwitch(campaignObj.campaignGeneralOptions?.automaticallyCheckOptIn);
  };

  const setAutoEmailStorage = (storage: AutoEmailStorage) => {
    window.top?.postMessage({ event: "setAutoEmail", load: storage }, "*");
  };

  const setSelectedOfferStorage = (storage: SelectedOfferStorage) => {
    window.top?.postMessage({ event: "setSelectedOffer", load: storage }, "*");
  };

  const submitAutoEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (single && email && validEmail) {
      setLoading(true);

      const _autoEmailItem: AutoEmailStorage = {
        offerLink: offer.trackingUrl,
        offerId: single.id,
        email: email,
      };

      setAutoEmailStorage(_autoEmailItem);
      setPreEmail && setPreEmail(email);

      setTimeout(() => {
        setEmailSent(true);
        setEmail(undefined);
        setLoading(false);
      }, 1e3);
    }
  };

  const submitSaveForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (single && email && validEmail) {
      setLoading(true);
      const emailParams: QuerySaveEmail = {
        offerid: single.id,
        email: email,
        offerLink: single.trackingUrl,
        placementId,
        companyId,
        campaignId: campaignId,
        slot: 0,
        orderId: offer.orderId,
        pltype: isDisplayOnly ? PlacementStage.pre : PlacementStage.post,
      };

      const response = await postTagEmailOffer(emailParams);
      getTrackingEvent({
        event: "onsendemailclick",
        retailerId: companyId,
        campaignId: campaignId,
        offerId: offer.id,
        placementId,
        placementName,
        slot: 0,
        pltype: isDisplayOnly ? PlacementStage.pre : PlacementStage.post,
      });

      if (response?.data?.status) {
        setEmail(undefined);
        setEmailSent(true);
      } else {
        handleClose();
      }
    }

    setLoading(false);
  };

  // Validate email on change
  useEffect(() => {
    if (offer.isAdded) {
      window.innerWidth > 768 && refAutoEmail.current?.focus();
    }
    if (isSingleSave) {
      window.innerWidth > 768 && refSaveEmail.current?.focus();
    }
    // eslint-disable-next-line
  }, [offer.isAdded, isSingleSave]);

  // Validate email on change
  useEffect(() => {
    if (email) {
      const isValid = EMAIL_REGEX.test(email);
      !single?.isAdded && handleAdd(offer, true);
      setValidEmail(isValid);
    }
    // eslint-disable-next-line
  }, [email]);

  useEffect(() => {
    const handleHref = (url: string) => {
      window.open(url, "_blank");
    };

    const handleHrefClick = (e: any) => {
      const _href = e.target?.href || undefined;
      if (_href) {
        e.preventDefault();
        handleHref(_href);
      }
    };

    window.addEventListener("click", handleHrefClick);

    return () => window.removeEventListener("click", handleHrefClick);
  }, []);

  return (
    <>
      {single && deviceSettings && (
        <div
          onClick={handleClickSingleWrapper}
          className={`opl-wrapper opl-wrapper-center `}
        >
          {isAuto ? (
            <></>
          ) : (
            <>
              <div
                className="single-box"
                ref={refSingle}
                style={{
                  backgroundColor: deviceSettings?.general.backgroundColor,
                  color: deviceSettings?.text.textColor,
                  fontFamily: deviceSettings.text.fontFamily,
                }}
              >
                <div className="offer-side">
                  <div className="side-image">
                    <img src={single?.imageUrl} alt={single?.title} />
                  </div>

                  {!isDisplayOnly ? (
                    <>
                      <div className="side-actions">
                        <div
                          className="action-get button"
                          onMouseEnter={() => {
                            setHover(true);
                          }}
                          onMouseLeave={() => {
                            setHover(false);
                          }}
                          style={{
                            color: deviceSettings.getButton.textColor,
                            fontSize: `${deviceSettings.getButton.fontSize}px`,
                            fontFamily: deviceSettings.getButton.fontFamily,
                            fontWeight: deviceSettings.getButton.fontWeight,
                            backgroundColor: hover
                              ? `${
                                  deviceSettings.getButton?.borderColor ||
                                  deviceSettings.getButton.textColor
                                }40`
                              : deviceSettings.getButton.backgroundColor,
                            borderRadius: deviceSettings.getButton.rounding,
                            border: `2px solid ${
                              deviceSettings.getButton?.borderColor ||
                              deviceSettings.getButton.backgroundColor
                            }`,
                            textTransform:
                              deviceSettings.getButton?.textTransform ||
                              "initial",
                          }}
                          onClick={() => handleGetGift(single.trackingUrl)}
                        >
                          {single?.offerCTAText || "Get offer now"}
                        </div>

                        {campaignObj.campaignGeneralOptions?.saveOfferOptions
                          .allowSavingOffers &&
                          campaignObj.campaignGeneralOptions?.saveOfferOptions
                            .allowEmail && (
                            <div
                              className="action-save"
                              onClick={handleToggleSave}
                              style={{
                                color: deviceSettings?.text.textColor,
                                fontFamily: deviceSettings.text.fontFamily,
                              }}
                            >
                              <svg
                                width="12"
                                height="14"
                                viewBox="0 0 12 14"
                                fill={
                                  isSingleSave
                                    ? deviceSettings?.text.textColor
                                    : "none"
                                }
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11 13L6 9.66667L1 13V2.33333C1 1.97971 1.15051 1.64057 1.41842 1.39052C1.68633 1.14048 2.04969 1 2.42857 1H9.57143C9.95031 1 10.3137 1.14048 10.5816 1.39052C10.8495 1.64057 11 1.97971 11 2.33333V13Z"
                                  stroke={deviceSettings?.text.textColor}
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              Save offer
                            </div>
                          )}
                      </div>
                    </>
                  ) : (
                    <>
                      {campaignObj.campaignGeneralOptions?.showAddToOrder && (
                        <div className="side-actions">
                          <div className="opl-switch-wrapper">
                            <label>
                              <input
                                className="opl-input"
                                type="checkbox"
                                checked={single.isAdded || false}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                  handleAdd(single, e.target.checked)
                                }
                              />
                              <span className="opl-switch-toggle">
                                <span className="opl-switch-toggle-before"></span>
                              </span>
                              <span className="opl-switch-text">
                                {single.isAdded ? (
                                  <span className="single-checked-text">
                                    Selected
                                  </span>
                                ) : (
                                  <span className="single-unchecked-text">
                                    Select
                                  </span>
                                )}
                              </span>
                            </label>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="offer-content">
                  <div className="content-title">
                    <span style={{ color: deviceSettings?.header.textColor }}>
                      {single?.title}
                    </span>
                    {/* <svg className="single-close" onClick={() => handleCloseSingle()} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.12634 1.12829C1.4276 0.827028 1.91604 0.827028 2.2173 1.12829L6.30039 5.21138L10.3835 1.12829C10.6847 0.827028 11.1732 0.827028 11.4744 1.12829C11.7757 1.42955 11.7757 1.91799 11.4744 2.21925L7.39136 6.30234L11.4744 10.3854C11.7757 10.6867 11.7757 11.1751 11.4744 11.4764C11.1732 11.7777 10.6847 11.7777 10.3835 11.4764L6.30039 7.39331L2.2173 11.4764C1.91604 11.7777 1.4276 11.7777 1.12634 11.4764C0.825075 11.1751 0.825075 10.6867 1.12634 10.3854L5.20943 6.30234L1.12634 2.21925C0.825075 1.91799 0.825075 1.42955 1.12634 1.12829Z" />
                                    </svg> */}
                    <svg
                      className="single-close"
                      onClick={() => handleCloseSingle()}
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.176625 0.34468C0.385958 0.133389 0.710902 0.109912 0.945926 0.274249L1.02944 0.34468L7.41698 6.79213L13.9706 0.178277C14.2061 -0.0594256 14.5879 -0.0594256 14.8234 0.178277C15.0327 0.389568 15.056 0.717552 14.8932 0.954775L14.8234 1.03907L8.2698 7.65293L14.5195 13.9609C14.755 14.1986 14.755 14.584 14.5195 14.8217C14.3102 15.033 13.9852 15.0565 13.7502 14.8922L13.6667 14.8217L7.41698 8.51372L1.17826 14.8118C0.942765 15.0495 0.560944 15.0495 0.325445 14.8118C0.116112 14.6005 0.0928526 14.2726 0.255667 14.0353L0.325445 13.951L6.56416 7.65293L0.176625 1.20548C-0.0588749 0.967774 -0.0588749 0.582382 0.176625 0.34468Z"
                        fill={deviceSettings?.header.textColor}
                      />
                    </svg>
                  </div>

                  {!isSingleSave ? (
                    <>
                      <div className="content">
                        {(single?.longDescription || single.description) && (
                          <div className="content-panel">
                            <div
                              className="panel-header"
                              onClick={() => setPanel(SinglePanels.overview)}
                            >
                              <span>Overview</span>
                              <svg
                                className={
                                  panel === SinglePanels.overview
                                    ? "rotate"
                                    : ""
                                }
                                width="11"
                                height="6"
                                viewBox="0 0 11 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.78033 5.78033C1.48744 6.07323 1.01256 6.07323 0.71967 5.78033C0.426777 5.48744 0.426777 5.01256 0.71967 4.71967L5.21967 0.219671C5.5036 -0.0642633 5.96079 -0.0741975 6.25679 0.197135L10.7568 4.32214C11.0621 4.60203 11.0828 5.07646 10.8029 5.3818C10.523 5.68713 10.0485 5.70776 9.74321 5.42787L5.77256 1.7881L1.78033 5.78033Z"
                                  fill={deviceSettings?.text.textColor}
                                />
                              </svg>
                            </div>

                            {panel === SinglePanels.overview && (
                              <div
                                className="panel-body"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    single.longDescription ||
                                    single.description,
                                }}
                              ></div>
                            )}
                          </div>
                        )}
                        {single?.faqs && (
                          <div className="content-panel">
                            <div
                              className="panel-header"
                              onClick={() => setPanel(SinglePanels.faqs)}
                            >
                              <span>FAQs</span>
                              <svg
                                className={
                                  panel === SinglePanels.faqs ? "rotate" : ""
                                }
                                width="11"
                                height="6"
                                viewBox="0 0 11 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.78033 5.78033C1.48744 6.07323 1.01256 6.07323 0.71967 5.78033C0.426777 5.48744 0.426777 5.01256 0.71967 4.71967L5.21967 0.219671C5.5036 -0.0642633 5.96079 -0.0741975 6.25679 0.197135L10.7568 4.32214C11.0621 4.60203 11.0828 5.07646 10.8029 5.3818C10.523 5.68713 10.0485 5.70776 9.74321 5.42787L5.77256 1.7881L1.78033 5.78033Z"
                                  fill={deviceSettings?.text.textColor}
                                />
                              </svg>
                            </div>
                            {panel === SinglePanels.faqs && (
                              <div
                                className="panel-body"
                                dangerouslySetInnerHTML={{
                                  __html: single?.faqs,
                                }}
                              ></div>
                            )}
                          </div>
                        )}
                        {single?.terms && (
                          <div className="content-panel">
                            <div
                              className="panel-header"
                              onClick={() => setPanel(SinglePanels.terms)}
                            >
                              <span>Terms</span>
                              <svg
                                className={
                                  panel === SinglePanels.terms ? "rotate" : ""
                                }
                                width="11"
                                height="6"
                                viewBox="0 0 11 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.78033 5.78033C1.48744 6.07323 1.01256 6.07323 0.71967 5.78033C0.426777 5.48744 0.426777 5.01256 0.71967 4.71967L5.21967 0.219671C5.5036 -0.0642633 5.96079 -0.0741975 6.25679 0.197135L10.7568 4.32214C11.0621 4.60203 11.0828 5.07646 10.8029 5.3818C10.523 5.68713 10.0485 5.70776 9.74321 5.42787L5.77256 1.7881L1.78033 5.78033Z"
                                  fill={deviceSettings?.text.textColor}
                                />
                              </svg>
                            </div>
                            {panel === SinglePanels.terms && (
                              <div
                                className="panel-body"
                                dangerouslySetInnerHTML={{
                                  __html: single?.terms,
                                }}
                              ></div>
                            )}
                          </div>
                        )}
                        {isDisplayOnly &&
                          campaignObj.campaignGeneralOptions
                            ?.emailCaptureOnOffers && (
                            <div className="content-panel">
                              <div
                                className="panel-header"
                                onClick={() => {
                                  setPanel(SinglePanels.autoEmail);
                                  setTimeout(() => {
                                    window.innerWidth > 768 &&
                                      refAutoEmail.current?.focus();
                                  });
                                }}
                              >
                                <span>Email</span>
                                <svg
                                  className={
                                    panel === SinglePanels.autoEmail
                                      ? "rotate"
                                      : ""
                                  }
                                  width="11"
                                  height="6"
                                  viewBox="0 0 11 6"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.78033 5.78033C1.48744 6.07323 1.01256 6.07323 0.71967 5.78033C0.426777 5.48744 0.426777 5.01256 0.71967 4.71967L5.21967 0.219671C5.5036 -0.0642633 5.96079 -0.0741975 6.25679 0.197135L10.7568 4.32214C11.0621 4.60203 11.0828 5.07646 10.8029 5.3818C10.523 5.68713 10.0485 5.70776 9.74321 5.42787L5.77256 1.7881L1.78033 5.78033Z"
                                    fill={deviceSettings?.text.textColor}
                                  />
                                </svg>
                              </div>
                              {panel === SinglePanels.autoEmail && (
                                <div className="panel-body">
                                  <form
                                    className={`${
                                      isLoading ? "loading-overlay" : ""
                                    }`}
                                    onSubmit={submitAutoEmail}
                                  >
                                    {isLoading && (
                                      <div className="icon-spin">
                                        <svg
                                          className="spin"
                                          viewBox="0 0 1024 1024"
                                          focusable="false"
                                          data-icon="loading"
                                          width="1em"
                                          height="1em"
                                          fill="currentColor"
                                          aria-hidden="true"
                                        >
                                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                        </svg>
                                      </div>
                                    )}
                                    <div
                                      className="content-panel-email-input"
                                      style={{
                                        fontFamily:
                                          deviceSettings.text.fontFamily,
                                      }}
                                    >
                                      <div className="content-panel-input-description">
                                        Enter your email and we will email you
                                        after you make a purchase
                                      </div>
                                      <div className="content-panel-input">
                                        <input
                                          type="text"
                                          ref={refAutoEmail}
                                          onChange={(e) =>
                                            setEmail(e.target.value)
                                          }
                                          value={email || ""}
                                          placeholder="Enter your email..."
                                        />
                                      </div>
                                      <div className="label-wrapper">
                                        {emailSent &&
                                          typeof email === "undefined" && (
                                            <span className="label-success">
                                              {" "}
                                              Email will be sent automatically
                                            </span>
                                          )}
                                        {!validEmail && email && (
                                          <span className="label-error">
                                            Must be valid email!
                                          </span>
                                        )}
                                      </div>
                                    </div>

                                    <div className="content-panel-submit">
                                      <div className="submit-checkbox">
                                        <label
                                          className="label-message"
                                          htmlFor="accept-email"
                                        >
                                          <input
                                            id="accept-email"
                                            type="checkbox"
                                            checked={emailSwitch}
                                            onChange={(e) =>
                                              setEmailSwitch(e.target.checked)
                                            }
                                          />
                                          <div className="checkboxsvg"></div>I
                                          have read and agree to the{" "}
                                          <a
                                            href="https://brandswap.com/privacy/"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            style={{
                                              color:
                                                deviceSettings?.text.textColor,
                                            }}
                                          >
                                            Privacy Policy
                                          </a>
                                        </label>
                                      </div>
                                      <button
                                        type="submit"
                                        className="button"
                                        disabled={!validEmail || !emailSwitch}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              )}
                            </div>
                          )}
                        <div className="content-extra">
                          {/* <a href="https://www.brandswap.com" rel="noopener noreferrer" target="_blank" style={{ color: deviceSettings?.text.textColor }}>
                                        Powered by BrandSwap
                                    </a> */}
                          <span
                            style={{ color: deviceSettings?.header.textColor }}
                          >
                            Powered by BrandSwap
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="content">
                        <form
                          className={`content-panel ${
                            isLoading ? "loading-overlay" : ""
                          }`}
                          onSubmit={submitSaveForm}
                        >
                          {isLoading && (
                            <div className="icon-spin">
                              <svg
                                className="spin"
                                viewBox="0 0 1024 1024"
                                focusable="false"
                                data-icon="loading"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                              </svg>
                            </div>
                          )}
                          <div
                            className="content-panel-email-input"
                            style={{
                              fontFamily: deviceSettings.text.fontFamily,
                            }}
                          >
                            <div className="content-panel-input-title">
                              Enter your email
                            </div>
                            <div className="content-panel-input-description">
                              Enter your email and we will email you the offer
                            </div>
                            <div className="content-panel-input">
                              <input
                                type="text"
                                ref={refSaveEmail}
                                onChange={(e) => setEmail(e.target.value)}
                                value={email || ""}
                                placeholder="Enter your email..."
                              />
                            </div>
                            <div className="label-wrapper">
                              {emailSent && typeof email === "undefined" && (
                                <span className="label-success">
                                  {" "}
                                  Email is sent
                                </span>
                              )}
                              {!validEmail && email && (
                                <span className="label-error">
                                  Must be valid email!
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="content-panel-submit">
                            <div className="submit-checkbox">
                              <label
                                className="label-message"
                                htmlFor="accept-email"
                              >
                                <input
                                  id="accept-email"
                                  type="checkbox"
                                  checked={emailSwitch}
                                  onChange={(e) =>
                                    setEmailSwitch(e.target.checked)
                                  }
                                />
                                <div className="checkboxsvg"></div>I have read
                                and agree to the{" "}
                                <a
                                  href="https://brandswap.com/privacy/"
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  style={{
                                    color: deviceSettings?.text.textColor,
                                  }}
                                >
                                  Privacy Policy
                                </a>
                              </label>
                            </div>
                            <button
                              type="submit"
                              className="button"
                              disabled={!validEmail || !emailSwitch}
                            >
                              Send
                            </button>
                          </div>
                        </form>

                        <div className="content-extra">
                          {/* <a href="https://www.brandswap.com" rel="noopener noreferrer" target="_blank" style={{ color: deviceSettings?.text.textColor }}>
                                        Powered by BrandSwap
                                    </a> */}
                          <span
                            style={{ color: deviceSettings?.header.textColor }}
                          >
                            Powered by BrandSwap
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SingleOffer;
