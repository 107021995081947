import { useEffect, useState } from "react";
import { CampaignType, LayoutDeviceSettings, Offer, QueryParams } from "../../types/global.types";
import LayoutError from "../layout/emailError";
import SingleOffer from "../layout/single";

const WrapperSingle = () => {
    const [params, setParams] = useState<QueryParams>();
    const [settings, setSettings] = useState<LayoutDeviceSettings>();
    const [campaignId, setCampaignId] = useState<string>();
    const [companyId, setComapnyId] = useState<string>();
    const [placementId, setPlacementId] = useState<string>();
    const [placementName, setPlacementName] = useState<string>("");
    const [campaignObj, setCampaign] = useState<CampaignType>();
    const [sessionId, setSessionId] = useState<string>('');

    const [emailPopupOffer, setEmailPopupOffer] = useState<Offer | undefined>(undefined);

    const [single, setSingle] = useState<Offer | undefined>();
    const [isSingleSave, setSingleSave] = useState<boolean>(false);
    const [isSingleAuto, setSingleAuto] = useState<boolean>(false);

    const [isDisplayOnly, setDisplayOnly] = useState<boolean>(false);



    useEffect(() => {
        window.addEventListener('message', function (event) {
            if (Object.keys(event.data).includes('id')) {
                setCampaignId(event.data?.id);
            }
            if (Object.keys(event.data).includes('sessionId')) {
                setSessionId(event?.data.sessionId);
            }
            if (Object.keys(event.data).includes('options')) {
                setSettings(event.data?.options);
            }
            if (Object.keys(event.data).includes('queryParams')) {
                setParams(event?.data.queryParams);
            }
            if (Object.keys(event.data).includes('placementId')) {
                setPlacementId(event?.data.placementId);
            }
            if (Object.keys(event.data).includes('placementName')) {
                setPlacementName(event?.data.placementName);
            }
            if (Object.keys(event.data).includes('emailPopup')) {
                setEmailPopupOffer(event?.data.emailPopup);
            }

            if (Object.keys(event.data).includes('placementName')) {
                setPlacementName(event?.data.placementName);
            }

            if (Object.keys(event.data).includes('extra')) {
                setSingle(event?.data.extra.offer);
                setSingleSave(event?.data.extra.isSave);
                setSingleAuto(event?.data.extra.isAuto);
                setDisplayOnly(event?.data.extra.isDisplayOnly);
                setSettings(event?.data.extra.settings);
                setParams(event?.data.extra.queryParams);
                setCampaign(event?.data.extra.campaign);
                setComapnyId(event.data.extra.companyId);
                setCampaignId(event.data.extra.campaignId);
                setPlacementName(event?.data.extra.placementName);
                setPlacementId(event?.data.extra.placementId);
                setEmailPopupOffer(event?.data.extra.offer);
            }
        });

        return () => {
            window.removeEventListener('message', () => { });
        }
    })


    useEffect(()=>{
        if(sessionId){
            sessionStorage.setItem('usersessionid', sessionId);
        }
    },[sessionId])

    return <>
        {params && campaignId && companyId && placementId && settings && emailPopupOffer && campaignObj && single ?
            <SingleOffer
                deviceSettings={settings.inline}
                isInline={true}
                offer={single}
                placementId={placementId}
                placementName={placementName}
                campaignObj={campaignObj}
                isSave={isSingleSave}
                isAuto={isSingleAuto}
                isDisplayOnly={isDisplayOnly}
                campaignId={campaignId}
                preEmail={params?.contactDetails ? params.contactDetails[0]?.email : undefined}
                companyId={companyId}
            />
            :
            <>
                <LayoutError></LayoutError>
            </>
        }
    </>

}

export default WrapperSingle