import React from "react";
import WrapperSingle from "../components/wrapper/wrapper-single";

function SinglePage() {
  return (
    <>
       <WrapperSingle />
    </>
  );
}

export default SinglePage;
